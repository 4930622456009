<template>
  <div id="social-essay-page">

    <div class="flex justify-end mt-8">
      <router-link to="/social-essays" type="button" class="btn btn-outline-secondary w-24">返回</router-link>
    </div>

    <div v-if="checkPermission(permissions, 'site.announcement.view')" class="flex flex-col md:flex-row mt-2">
      <div v-if="data != null" class="w-full md:w-3/5">
        <div class="intro-y p-5 box">
          <!-- BEGIN: Blog Layout -->
          <h2 class="intro-y font-medium text-xl sm:text-2xl">
            {{ data.title }}
          </h2>
          <div class="intro-y text-slate-600 dark:text-slate-500 mt-3 text-xs sm:text-sm flex justify-between align-items-center">
            <div>
              {{ $moment(data.post_date).format('YYYY-MM-DD') }} <span class="mx-1">•</span> 已讀 <span class="mx-1">•</span> 最後更新 {{ $moment(data.updated_at).format('YYYY-MM-DD') }} <span v-if="data.like_count > 0" class="mx-1">•</span> <span v-if="data.like_count > 0">{{ data.like_count }}個讚好</span>
            </div>
            <div>
              <button type="button" class="btn" :class="{'btn-primary': is_like, 'btn-secondary': !is_like}" @click="onToggleLike"><font-awesome-icon icon="fa-solid fa-thumbs-up" class="mr-1" /> {{ is_like ? '已' : ''}}讚好</button>
            </div>
          </div>
          <div class="intro-y text-justify html-content mt-6" v-html="data.content">
          </div>
          <div
            v-if="author != null"
            class="intro-y flex text-xs sm:text-sm flex-col sm:flex-row items-center mt-5 pt-5 border-t border-slate-200/60 dark:border-darkmode-400">
            <div class="flex items-center">
              <div class="w-12 h-12 flex-none image-fit">
                <img alt="" class="rounded-full" :src="author.avatar_url != null && author.avatar_url !== '' ? author.avatar_url : '/images/user.png'">
              </div>
              <div class="ml-3 mr-auto">
                <div class="font-medium">{{ author.first_name + " " + author.last_name }}</div>
                <div class="text-slate-500">{{ author.username }}</div>
              </div>
            </div>
          </div>
          <!-- END: Blog Layout -->
        </div>
      </div>

      <div class="flex-1 ml-5">
        <h2 class="text-lg font-medium">
          留言
        </h2>

        <div class="text-xs sm:text-sm flex-col sm:flex-row items-center mt-2 mb-5 box p-5">
          <div id="comments" class="overflow-y-auto">
            <div v-for="(item, key) in comments" :key="key" class="flex items-center py-1" :class="{'border-b-2': key !== comments.length - 1}">
              <div class="w-6 h-6 flex-none image-fit">
                <img alt="" class="rounded-full" :src="item.user.avatar_url != null && item.user.avatar_url !== '' ? item.avatar_url : '/images/user.png'">
              </div>
              <div class="ml-3 mr-3 w-full">
                <div class="font-medium">
                  {{ item.user.first_name + " " + item.user.last_name }}
                </div>
                <div class="text-slate-500">{{ item.comment }}</div>
                <small class="text-slate-500">
                  <i>{{ item.created_at }}</i>
                  <button v-if="profile.user_id === item.user_id" type="button" class="ml-2 text-primary" @click="openModal(item)"><font-awesome-icon icon="fa-solid fa-pencil"  /></button></small>
              </div>
              <div></div>
            </div>
          </div>

          <div v-if="comments.length === 0" class="text-center text-pending mb-4">未有留言</div>

          <div class="relative text-slate-500 mt-2">
            <input type="text" class="form-control py-3 px-4 border-transparent bg-slate-100 pr-10" placeholder="輸入留言" v-model="form.comment" @keydown.enter.exact.prevent="onComment">
            <button class="w-4 h-4 sm:absolute my-auto inset-y-0 mr-3 right-0" @click="onComment">
              <font-awesome-icon icon="fa-solid fa-paper-plane"  />
            </button>
          </div>
        </div>
      </div>

      <!-- BEGIN: Update Comment Modal -->
      <div id="update-comment-modal" class="modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h2 class="font-medium text-base mr-auto">
                更改留言
              </h2>
            </div>
            <div class="modal-body p-0">
              <div class="p-5">
                <div class="text-slate-500">
                  <div class="">
                    <label class="form-label">內容</label>
                    <input type="text" v-model="updateForm.comment" placeholder="請輸入內容" class="form-control w-full"/>
                  </div>
                </div>
              </div>
              <div class="px-5 pb-8 text-center">
                <button type="button" data-tw-dismiss="modal" class="btn btn-outline-secondary w-24 mr-1">取消
                </button>
                <button type="button" data-tw-dismiss="modal" class="btn btn-primary w-24 text-white"
                        :disabled="updateForm.comment == null || updateForm.comment === ''"
                        @click="onUpdateComment">確定
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END: Update Comment Modal -->
    </div>

    <div v-else-if="mounted">
      <div class="alert alert-danger show mt-2" role="alert">沒有權限查看</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['auth', 'currentSite', 'permissions', 'profile'],
  data() {
    return {
      mounted: false,
      data: null,
      users: [],
      author: null,
      is_like: false,
      comments: [],

      form: {
        comment: null
      },

      updateForm: {
        comment: null
      },

      itemPerPage: 999999,
      currentPage: 1,
      pages: 0,

      selectComment: null
    }
  },
  watch: {
    auth(val) {
      if (val) {
        this.init()
      }
    },
    async currentSite(val) {
      await this.init()
    }
  },
  async mounted() {
    if (!this.auth) {
      return
    }

    await this.init()
  },
  methods: {
    async init() {
      if (this.currentSite) {
        await this.loadData()

        this.mounted = true
      }
    },
    async loadData() {
      this.data = await this.getSiteEssay(this.currentSite.site_id, this.$route.params.essayId)
      this.is_like = this.data.is_like
      await this.loadUser()
      await this.loadComments()
    },
    async loadUser() {
      this.users = await this.getUsers()
      this.author = this.getObjectValue(this.users, 'user_id', this.data.user_id)
    },
    async loadComments() {
      const comments = await this.getSiteEssayComments(this.currentSite.site_id, this.$route.params.essayId, 999999)
      for (let i = 0; i < comments.length; i++) {
        comments[i].user = this.getObjectValue(this.users, 'user_id', comments[i].user_id)
      }

      this.comments = comments
    },
    async onToggleLike() {
      let response
      if (this.is_like) {
        response = await this.unlikeSiteEssay(this.currentSite.site_id, this.$route.params.essayId)
      } else {
        response = await this.likeSiteEssay(this.currentSite.site_id, this.$route.params.essayId)
      }

      if (response.code === 0) {
        this.is_like = !this.is_like
        this.loadData()
      }
    },
    async onComment() {
      if (this.form.comment == null || this.form.comment === '') {
        return
      }

      const response = await this.createSiteEssayComment(this.currentSite.site_id, this.$route.params.essayId, this.form)

      if (response.code === 0) {
        this.form.comment = null
        this.loadComments()
        this.scrollToBottom()
      }
    },
    scrollToBottom(animation = 'smooth') {
      // Scroll to bottom
      setTimeout(() => {
        const lastElement = document.getElementById('comments').lastElementChild
        if (lastElement != null) {
          lastElement.scrollIntoView({ behavior: animation })
        }
      }, 100)
    },
    openModal(item) {
      this.selectComment = item
      this.updateForm.comment = item.comment

      const el = document.querySelector('#update-comment-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.show()
    },
    async onUpdateComment() {
      const response = await this.updateSiteEssayComment(this.currentSite.site_id, this.$route.params.essayId, this.selectComment.comment_id, this.updateForm)

      let option = {
        title: '成功',
        message: '已更改留言',
        type: 'success'
      }

      if (response.code === 0) {
        const el = document.querySelector('#update-comment-modal')
        const modal = window.tailwind.Modal.getOrCreateInstance(el)
        modal.hide()

        this.form.comment = null
        this.loadComments()
      } else {
        option = {
          title: '未能更改留言',
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        }
      }

      this.$notify(option)
    }
  }
}
</script>

<style lang="scss" scoped>
#social-essay-page {
  #comments {
    max-height: 500px;
  }
}
</style>

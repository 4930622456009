<template>
  <div id="site-form">
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-6">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box p-5">

          <div class="mt-3">
            <label for="name" class="form-label"><span class="text-danger">*</span> 分站名稱</label>
            <input v-model="form.name" id="name" type="text" class="form-control w-full" placeholder="請輸入分站登入名">
            <div v-if="'name' in errors" class="text-danger mt-2" v-html="errors.name"></div>
          </div>

          <div class="mt-3">
            <label for="description" class="form-label">分站簡介</label>
            <input v-model="form.description" id="description" type="text" class="form-control w-full" placeholder="請輸入分站簡介">
            <div v-if="'description' in errors" class="text-danger mt-2" v-html="errors.description"></div>
          </div>

          <div class="mt-3">
            <label for="type" class="form-label">分站類別</label>
            <select id="type" v-model="form.type" class="form-select" aria-label="請選擇類別">
              <option value="">--- 請選擇 ---</option>
              <option v-for="(item, key) in siteTypes" :key="key" :value="item.value">{{ item.name }}</option>
            </select>
            <div v-if="'type' in errors" class="text-danger mt-2" v-html="errors.type"></div>
          </div>

          <div class="mt-3" v-if="this.mode === 'update'">
            <div class="sm:grid grid-cols-2 gap-2">
              <div>
                <label for="high_level_role_id" class="form-label">主任級或以上的職能</label>
                <select id="high_level_role_id" v-model="form.high_level_role_id" class="form-select" aria-label="請選擇職能">
                  <option>--- 請選擇 ---</option>
                  <option v-for="(item, key) in allRoles" :key="key" :value="item.role_id">{{ item.name }}</option>
                </select>
                <div v-if="'high_level_role_id' in errors" class="text-danger mt-2" v-html="errors.high_level_role_id"></div>
              </div>

              <div>
                <label for="general_role_id" class="form-label">非主任級或以上的職能</label>
                <select id="general_role_id" v-model="form.general_role_id" class="form-select" aria-label="請選擇職能">
                  <option>--- 請選擇 ---</option>
                  <option v-for="(item, key) in allRoles" :key="key" :value="item.role_id">{{ item.name }}</option>
                </select>
                <div v-if="'general_role_id' in errors" class="text-danger mt-2" v-html="errors.general_role_id"></div>
              </div>
            </div>
          </div>

          <div class="mt-3">
            <div class="sm:grid grid-cols-2 gap-2">
              <div>
                <label class="form-label">報告板功能</label>
                <div class="form-switch mt-2">
                  <input v-model="form.is_announcement_enabled" type="checkbox" class="form-check-input">
                </div>

                <div v-if="'is_announcement_enabled' in errors" class="text-danger mt-2" v-html="errors.first_name"></div>
              </div>

              <div>
                <label class="form-label">行事曆功能</label>
                <div class="form-switch mt-2">
                  <input v-model="form.is_calendar_enabled" type="checkbox" class="form-check-input">
                </div>

                <div v-if="'is_calendar_enabled' in errors" class="text-danger mt-2" v-html="errors.last_name"></div>
              </div>
            </div>
          </div>

          <div class="mt-3">
            <div class="sm:grid grid-cols-2 gap-2">
              <div>
                <label class="form-label">通訊錄功能</label>
                <div class="form-switch mt-2">
                  <input v-model="form.is_phone_directory_enabled" type="checkbox" class="form-check-input">
                </div>

                <div v-if="'is_phone_directory_enabled' in errors" class="text-danger mt-2" v-html="errors.is_phone_directory_enabled"></div>
              </div>

              <div>
                <label class="form-label">申請表功能</label>
                <div class="form-switch mt-2">
                  <input v-model="form.is_workflow_enabled" type="checkbox" class="form-check-input">
                </div>

                <div v-if="'is_workflow_enabled' in errors" class="text-danger mt-2" v-html="errors.is_workflow_enabled"></div>
              </div>
            </div>
          </div>

          <div class="mt-3">
            <div class="sm:grid grid-cols-2 gap-2">
              <div>
                <label class="form-label">問巻/電子表格功能</label>
                <div class="form-switch mt-2">
                  <input v-model="form.is_form_enabled" type="checkbox" class="form-check-input">
                </div>

                <div v-if="'is_form_enabled' in errors" class="text-danger mt-2" v-html="errors.is_form_enabled"></div>
              </div>

              <div>
                <label class="form-label">會議室及資源預借功能</label>
                <div class="form-switch mt-2">
                  <input v-model="form.is_resource_booking_enabled" type="checkbox" class="form-check-input">
                </div>

                <div v-if="'is_resource_booking_enabled' in errors" class="text-danger mt-2" v-html="errors.is_resource_booking_enabled"></div>
              </div>
            </div>
          </div>

          <div class="mt-3">
            <div class="sm:grid grid-cols-2 gap-2">
              <div>
                <label class="form-label">FAQ功能</label>
                <div class="form-switch mt-2">
                  <input v-model="form.is_knowledge_base_enabled" type="checkbox" class="form-check-input">
                </div>

                <div v-if="'is_knowledge_base_enabled' in errors" class="text-danger mt-2" v-html="errors.is_knowledge_base_enabled"></div>
              </div>

              <div>
                <label class="form-label">討論區功能</label>
                <div class="form-switch mt-2">
                  <input v-model="form.is_forum_enabled" type="checkbox" class="form-check-input">
                </div>

                <div v-if="'is_forum_enabled' in errors" class="text-danger mt-2" v-html="errors.is_forum_enabled"></div>
              </div>
            </div>
          </div>

          <div class="mt-3">
            <div class="sm:grid grid-cols-2 gap-2">
              <div>
                <label class="form-label">下載預約記錄</label>
                <div class="form-switch mt-2">
                  <input v-model="form.is_roster_site" type="checkbox" class="form-check-input">
                </div>

                <div v-if="'is_roster_site' in errors" class="text-danger mt-2" v-html="errors.is_roster_site"></div>
              </div>
            </div>
          </div>

          <div class="flex justify-end align-items-center mt-5">
            <router-link v-if="!loading && !finish" to="/management/sites" type="button" class="btn btn-outline-secondary w-24 mr-1">取消</router-link>
            <button v-if="!loading && !finish" type="button" class="btn btn-primary w-24" @click="onSave">儲存</button>
            <span v-show="loading && !finish"><i data-loading-icon="oval" class="w-8 h-8"></i></span>
          </div>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['data'],
  data() {
    return {
      mode: 'create',
      form: {
        name: null,
        description: null,
        type: '',
        high_level_role_id: null,
        general_role_id: null,
        is_announcement_enabled: false,
        is_calendar_enabled: false,
        is_phone_directory_enabled: false,
        is_workflow_enabled: false,
        is_form_enabled: false,
        is_forum_enabled: false,
        is_knowledge_base_enabled: false,
        is_resource_booking_enabled: false,
        is_roster_site: false
      },
      errors: {},
      loading: false,
      finish: false,

      allRoles: []
    }
  },
  watch: {
    async data(val) {
      if (val != null) {
        this.form = this.data
        this.mode = 'update'

        await this.loadRoles()
      }
    }
  },
  mounted() {
    window.tailwind.svgLoader()
  },
  methods: {
    async loadRoles() {
      this.allRoles = await this.getRoles(this.data.site_id)
    },
    onSave: async function() {
      this.loading = true
      this.errors = {}

      let response

      if (this.mode === 'create') {
        response = await this.createSite(this.form)
      } else {
        response = await this.updateSite(this.data.site_id, this.form)
      }

      if ('errors' in response.data) {
        this.errors = response.data.errors
        this.loading = false
      } else {
        let option = {
          title: (this.mode === 'create') ? '已成功新增分站' : '已成功更新分站',
          message: '請稍後，將會轉至分站頁面',
          type: 'success'
        }

        if (response.code === 0) {
          this.finish = true

          // Update CurrentSite in App.vue
          await this.$root.$children[0].updateCurrentSiteData()

          setTimeout(() => {
            this.$router.push('/management/sites')
          }, 1000)
        } else {
          this.loading = false
          option = {
            title: (this.mode === 'create') ? '未能新增分站' : '未能更新分站',
            message: response.data.message ?? '系統錯誤，請稍後再試',
            type: 'error'
          }
        }

        this.$notify(option)
      }
    }
  }
}
</script>

<style scoped lang="scss"></style>

<template>
  <div id="contact-form">
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-6">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box p-5">

          <div class="mt-3">
            <label for="site_id" class="form-label">分站</label>
            <div id="site_id" class="w-full" v-if="site">{{ site.name }}</div>
            <div v-if="'site_id' in errors" class="text-danger mt-2" v-html="errors.site_id"></div>
          </div>

          <div class="mt-3">
            <label for="user_id" class="form-label">用戶</label>
            <div id="user_id" class="w-full" v-if="data != null">{{ data.user.full_name ?? '-' }}</div>
          </div>

          <div class="mt-3">
            <label for="staff_number" class="form-label">職員編號</label>
            <div id="user_id" class="w-full" v-if="data != null">{{ data.staff_number ?? '-' }}</div>
          </div>

          <div class="mt-3">
            <div class="flex justify-between align-items-center mb-1">
              <label class="form-label">大頭圖像</label>
              <button type="button" class="btn btn-danger" v-if="form.avatar != null" @click="onRemoveImage">移除</button>
            </div>

            <form data-single="true" action="/" class="dropzone">
              <div class="fallback"> <input name="file" type="file" /> </div>
              <div class="dz-message" data-dz-message>
                <div class="text-lg font-medium">將圖片拖放到此處或單擊以上傳</div>
                <div class="text-slate-500"> 支援檔案：JPG, JPEG, PNG, BMP, GIF </div>
              </div>
            </form>
            <div v-if="'avatar' in errors" class="text-danger mt-2" v-html="errors.avatar"></div>
          </div>

          <div class="mt-3" v-if="mode === 'update' && data!= null && data.avatar">
            <label for="site_id" class="form-label">現時圖像</label>
            <a :href="serverUrl + '/public-resources' + data.avatar" target="_blank" class="block w-48 h-48 flex-none image-fit rounded-md overflow-hidden">
              <img alt="大頭圖像" :src="serverUrl + '/public-resources' + data.avatar">
            </a>
          </div>

          <div class="mt-3">
            <label for="centre_id" class="form-label">所屬部/組/服務單位</label>
            <select id="centre_id" v-model="form.centre_id" class="form-select" aria-label="請選擇分站" @change="onSelectCenter">
              <option value="">--- 請選擇 ---</option>
              <option v-for="(item, key) in centers" :key="key" :value="item.centre_id">{{ item.abbrev + ` (${item.name_en})` }}</option>
            </select>
            <div v-if="'centre_id' in errors" class="text-danger mt-2" v-html="errors.centre_id"></div>
          </div>

          <div class="mt-3">
            <div class="flex">
              <div class="flex-1 ml-1">
                <label for="position" class="form-label">職位 (英文)</label>
                <input v-model="form.position" id="position" type="text" class="form-control w-full" placeholder="請輸入職位(英文)">
                <div v-if="'position' in errors" class="text-danger mt-2" v-html="errors.position"></div>
              </div>
              <div class="flex-1 ml-1">
                <label for="position_zh" class="form-label">職位 (中文)</label>
                <input v-model="form.position_zh" id="position_zh" type="text" class="form-control w-full" placeholder="請輸入職位(中文)">
                <div v-if="'position_zh' in errors" class="text-danger mt-2" v-html="errors.position_zh"></div>
              </div>
              <div class="flex-1 ml-1">
                <label for="position_sn" class="form-label">職位 (簡稱)</label>
                <input v-model="form.position_sn" id="position_sn" type="text" class="form-control w-full" placeholder="請輸入職位(簡稱)">
                <div v-if="'position_sn' in errors" class="text-danger mt-2" v-html="errors.position_sn"></div>
              </div>
            </div>
          </div>

          <div class="mt-3">
            <div class="flex">
              <div class="flex-1 mr-1">
                <label class="form-label">主任級或以上</label>
                <div class="form-switch mt-2">
                  <input v-model="form.is_high_level" type="checkbox" class="form-check-input">
                </div>
                <div v-if="'is_high_level' in errors" class="text-danger mt-2" v-html="errors.is_high_level"></div>
              </div>
              <div class="flex-1 ml-1">
                <label class="form-label">註冊社工</label>
                <div class="form-switch mt-2">
                  <input v-model="form.is_register_social_worker" type="checkbox" class="form-check-input">
                </div>
                <div v-if="'is_register_social_worker' in errors" class="text-danger mt-2" v-html="errors.is_register_social_worker"></div>
              </div>
            </div>
          </div>

          <div class="mt-3">
            <div class="flex">
              <div class="flex-1 mr-1">
                <label for="honorific_title" class="form-label">稱謂</label>
                <select id="honorific_title" v-model="form.honorific_title" class="form-select" aria-label="請選擇稱呼">
                  <option value="">--- 請選擇 ---</option>
                  <option v-for="(item, key) in titles" :key="key" :value="item.value">{{ item.key + ' ' + item.value }}</option>
                </select>
                <div v-if="'honorific_title' in errors" class="text-danger mt-2" v-html="errors.honorific_title"></div>
              </div>
              <div class="flex-1 mr-1">
                <label for="last_name" class="form-label">英文姓氏</label>
                <input v-model="form.last_name" id="last_name" type="text" class="form-control w-full" placeholder="請輸入姓氏">
                <div v-if="'last_name' in errors" class="text-danger mt-2" v-html="errors.last_name"></div>
              </div>
              <div class="flex-1 mr-1">
                <label for="other_name" class="form-label">英文中間名</label>
                <input v-model="form.other_name" id="other_name" type="text" class="form-control w-full" placeholder="請輸入中間名">
                <div v-if="'other_name' in errors" class="text-danger mt-2" v-html="errors.other_name"></div>
              </div>
              <div class="flex-1">
                <label for="first_name" class="form-label">英文名字</label>
                <input v-model="form.first_name" id="first_name" type="text" class="form-control w-full" placeholder="請輸入名字">
                <div v-if="'first_name' in errors" class="text-danger mt-2" v-html="errors.first_name"></div>
              </div>
            </div>
          </div>

          <div class="mt-3">
            <div class="flex">
              <div class="flex-1 mr-1">
                <label for="last_name_zh" class="form-label">中文姓氏</label>
                <input v-model="form.last_name_zh" id="last_name_zh" type="text" class="form-control w-full" placeholder="請輸入姓氏">
                <div v-if="'last_name_zh' in errors" class="text-danger mt-2" v-html="errors.last_name_zh"></div>
              </div>
              <div class="flex-1">
                <label for="first_name_zh" class="form-label">中文名字</label>
                <input v-model="form.first_name_zh" id="first_name_zh" type="text" class="form-control w-full" placeholder="請輸入名字">
                <div v-if="'first_name_zh' in errors" class="text-danger mt-2" v-html="errors.first_name_zh"></div>
              </div>
            </div>
          </div>

          <div class="mt-3" >
            <label for="display_name" class="form-label">顯示名稱</label>
            <input v-model="form.display_name" id="display_name" type="text" class="form-control w-full" placeholder="請輸入顯示名稱">
            <div v-if="'display_name' in errors" class="text-danger mt-2" v-html="errors.display_name"></div>
          </div>

          <div class="mt-3">
            <label for="company" class="form-label">公司</label>
            <input v-model="form.company" id="company" type="text" class="form-control w-full" placeholder="請輸入公司">
            <div v-if="'company' in errors" class="text-danger mt-2" v-html="errors.company"></div>
          </div>

          <div class="mt-3" >
            <label for="address" class="form-label">英文地址</label>
            <input v-model="form.address" id="address" type="text" class="form-control w-full" placeholder="請輸入地址">
            <div v-if="'address' in errors" class="text-danger mt-2" v-html="errors.address"></div>
          </div>

          <div class="mt-3" >
            <label for="address_zh" class="form-label">中文地址</label>
            <input v-model="form.address_zh" id="address_zh" type="text" class="form-control w-full" placeholder="請輸入地址">
            <div v-if="'address_zh' in errors" class="text-danger mt-2" v-html="errors.address_zh"></div>
          </div>

          <div class="mt-3" v-if="false">
            <div class="flex">
              <div class="flex-1 ml-1">
                <label for="center" class="form-label">中心名稱 (英文)</label>
                <input v-model="form.center" id="center" type="text" class="form-control w-full" placeholder="請輸入職位(英文)">
                <div v-if="'center' in errors" class="text-danger mt-2" v-html="errors.center"></div>
              </div>
              <div class="flex-1 ml-1">
                <label for="center_zh" class="form-label">中心名稱 (中文)</label>
                <input v-model="form.center_zh" id="center_zh" type="text" class="form-control w-full" placeholder="請輸入職位(中文)">
                <div v-if="'center_zh' in errors" class="text-danger mt-2" v-html="errors.center_zh"></div>
              </div>
              <div class="flex-1 ml-1">
                <label for="center_sn" class="form-label">中心名稱 (簡稱)</label>
                <input v-model="form.center_sn" id="center_sn" type="text" class="form-control w-full" placeholder="請輸入職位(簡稱)">
                <div v-if="'center_sn' in errors" class="text-danger mt-2" v-html="errors.center_sn"></div>
              </div>
            </div>
          </div>

          <div class="mt-3" >
            <label for="fax" class="form-label">傳真</label>
            <input v-model="form.fax" id="fax" type="text" class="form-control w-full" placeholder="請輸入傳真">
            <div v-if="'fax' in errors" class="text-danger mt-2" v-html="errors.fax"></div>
          </div>

          <div class="mt-3" >
            <label for="birthdate" class="form-label">出生日期</label>
            <div id="birthdate">
              <date-picker v-model="birthdate" type="date" placeholder="請選擇出生日期" input-class="form-control w-full" :disabled-date="notAfterDate"></date-picker>
            </div>
            <div v-if="'birthdate' in errors" class="text-danger mt-2" v-html="errors.birthdate"></div>
          </div>

          <div class="mt-3" >
            <label for="remark" class="form-label">備註</label>
            <input v-model="form.remark" id="remark" type="text" class="form-control w-full" placeholder="請輸入備註">
            <div v-if="'remark' in errors" class="text-danger mt-2" v-html="errors.remark"></div>
          </div>

          <div class="mt-5">
            <div class="flex justify-between align-items-center">
              <label for="remark" class="form-label"><span class="text-danger">*</span> 電郵地址</label>
              <button type="button" class="btn btn-primary" @click="form.emails.push({ email: null, label: null })"><font-awesome-icon :icon="['fas', 'plus']" /></button>
            </div>
            <div class="flex align-items-end mb-1" v-for="(item, key) in form.emails" :key="key">
              <div class="flex-1 mr-1">
                <label class="form-label">電郵</label>
                <input v-model="item.email" type="text" class="form-control w-full" placeholder="請輸入電郵">
              </div>
              <div class="flex-1 ml-1 mr-1">
                <label class="form-label">標籤</label>
                <input v-model="item.label" type="text" class="form-control w-full" placeholder="請輸入標籤">
              </div>
              <div class="ml-1">
                <button type="button" class="btn btn-danger btn-remove" @click="$delete(form.emails, key)"><font-awesome-icon :icon="['fas', 'xmark']" /></button>
              </div>
            </div>
            <div v-if="'emails' in errors" class="text-danger mt-2" v-html="errors.emails"></div>
          </div>

          <div class="mt-6">
            <div class="flex justify-between align-items-center">
              <label for="remark" class="form-label"><span class="text-danger">*</span> 電話號碼</label>
              <button type="button" class="btn btn-primary" @click="form.numbers.push({ number: null, label: null })"><font-awesome-icon :icon="['fas', 'plus']" /></button>
            </div>
            <div class="flex align-items-end mb-1" v-for="(item, key) in form.numbers" :key="key">
              <div class="flex-1 mr-1">
                <label class="form-label">電話</label>
                <input v-model="item.number" type="text" class="form-control w-full" placeholder="請輸入電話">
              </div>
              <div class="flex-1 ml-1 mr-1">
                <label class="form-label">標籤</label>
                <input v-model="item.label" type="text" class="form-control w-full" placeholder="請輸入標籤">
              </div>
              <div class="ml-1">
                <button type="button" class="btn btn-danger btn-remove" @click="$delete(form.numbers, key)"><font-awesome-icon :icon="['fas', 'xmark']" /></button>
              </div>
            </div>
            <div v-if="'numbers' in errors" class="text-danger mt-2" v-html="errors.numbers"></div>
          </div>

          <div class="flex justify-end align-items-center mt-5">
            <router-link v-if="!loading && !finish" to="/address-books" type="button" class="btn btn-outline-secondary w-24 mr-1">取消</router-link>
            <button v-if="!loading && !finish" type="button" class="btn btn-primary w-24" @click="onSave">儲存</button>
            <span v-show="loading && !finish"><i data-loading-icon="oval" class="w-8 h-8"></i></span>
          </div>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['site', 'data'],
  data() {
    return {
      mode: 'update',
      mounted: false,
      users: [],
      form: {
        centre_id: '',
        avatar: null,
        avatar_base64: null,
        user_id: null,
        honorific_title: '',
        first_name: null,
        last_name: null,
        other_name: null,
        first_name_zh: null,
        last_name_zh: null,
        display_name: null,
        company: null,
        position: null,
        position_zh: null,
        position_sn: null,
        address: null,
        address_zh: null,
        // center: null,
        // center_zh: null,
        // center_sn: null,
        fax: null,
        birthdate: null,
        remark: null,
        is_high_level: false,
        is_register_social_worker: false,
        emails: [{ email: null, label: null }],
        numbers: [{ number: null, label: null }]
      },
      errors: {},
      loading: false,
      finish: false,

      birthdate: null,

      centers: [],

      titles: [
        {
          key: '先生',
          value: 'Mr'
        },
        {
          key: '太太',
          value: 'Mrs'
        },
        {
          key: '小姐',
          value: 'Miss'
        },
        {
          key: '女士',
          value: 'Ms'
        },
        {
          key: '夫人',
          value: 'Madam'
        },
        {
          key: '博士',
          value: 'Dr'
        }
      ]
    }
  },
  computed: {
    serverUrl() {
      return '' + process.env.VUE_APP_SERVER_URL
    }
  },
  watch: {
    site(val) {
      this.init()
    },
    data(val) {
      this.init()
    }
  },
  created() {
  },
  async mounted() {
    window.tailwind.svgLoader()
    await this.loadCenters()
    await this.init()

    this.$nextTick(() => {
      this.dropZone()

      const self = this

      this.$dz.on('addedfile', function(file) {
        while (this.files.length > this.options.maxFiles) this.removeFile(this.files[0])

        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = function() {
          self.$set(self.form, 'avatar_base64', reader.result)
        }
      })
    })
  },
  methods: {
    async init() {
      if (this.site) {
        if (this.data) {
          this.form = this.data
          this.form.centre_id = this.data.centre_id ?? ''
          this.post_date = this.form.post_date != null ? this.$moment(this.form.post_date).toDate() : null
          this.display_at = this.form.display_at != null ? this.$moment(this.form.display_at).toDate() : null
          this.end_at = this.form.end_at != null ? this.$moment(this.form.end_at).toDate() : null
          this.mode = 'update'
        }
        this.mounted = true
      }
    },
    async loadCenters() {
      this.centers = await this.getCenters()
    },
    onSelectCenter(event) {
      const center = this.getObjectValue(this.centers, 'centre_id', event.target.value)
      this.form.address = center.address_en
      this.form.address_zh = center.address_zh
    },
    notAfterDate(date) {
      const now = new Date()
      return date > new Date(now.getFullYear(), now.getMonth(), now.getDate())
    },
    onRemoveImage() {
      this.$dz.removeAllFiles()
      this.$set(this.form, 'avatar_base64', null)
    },
    async onSave() {
      this.loading = true
      this.errors = {}

      this.form.site_id = this.site.site_id

      if (this.birthdate != null) {
        this.form.birthdate = this.$moment(this.birthdate).format()
      }

      const response = await this.updateSiteContact(this.site.site_id, this.data.contact_id, this.form)

      if (response.body && 'errors' in response.body) {
        this.errors = response.body.errors
        this.loading = false
      } else {
        let option = {
          title: (this.mode === 'create') ? '已成功新增聯絡人' : '已成功更新聯絡人',
          message: '請稍後，將會轉至通訊錄頁面',
          type: 'success'
        }

        if (response.code === 0) {
          this.finish = true

          setTimeout(() => {
            this.$router.push('/address-books')
          }, 1000)
        } else {
          this.loading = false
          option = {
            title: (this.mode === 'create') ? '未能新增聯絡人' : '未能更新聯絡人',
            message: response.data.message ?? '系統錯誤，請稍後再試',
            type: 'error'
          }
        }

        this.$notify(option)
      }
    }
  }
}
</script>

<style scoped lang="scss">
#contact-form {
  .btn-remove {
    height: 38px;
  }
}
</style>

<template>
  <div id="form-form">
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-6">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box p-5">

          <div class="">
            <label for="name" class="form-label">名稱</label>
            <input v-model="form.name" id="description" type="text" class="form-control w-full" placeholder="請輸入名稱">
            <div v-if="'name' in errors" class="text-danger mt-2" v-html="errors.name"></div>
          </div>

          <div class="mt-3">
            <label for="description" class="form-label">簡介</label>
            <input v-model="form.description" id="description" type="text" class="form-control w-full" placeholder="請輸入簡介">
            <div v-if="'description' in errors" class="text-danger mt-2" v-html="errors.description"></div>
          </div>

          <div class="mt-3">
            <label for="is_published">公開</label>
            <div class="form-switch mt-2">
              <input id="is_published" v-model="form.is_published" type="checkbox" class="form-check-input">
            </div>

            <div v-if="'is_published' in errors" class="text-danger mt-2" v-html="errors.is_published"></div>
          </div>

          <div class="mt-3">
            <label for="display_at" class="form-label">開始日期時間</label>
            <div id="display_at">
              <date-picker v-model="from_time" type="datetime" placeholder="請選擇問卷開始日期時間" input-class="form-control w-full" :disabled-date="notBeforeDate" :disabled-time="notBeforeTime" :time-picker-options="{start: '00:00', step:'00:30' , end: '23:00', format: 'HH:mm'}" format="YYYY-MM-DD HH:mm"></date-picker>
            </div>
            <div v-if="'from_time' in errors" class="text-danger mt-2" v-html="errors.from_time"></div>
          </div>

          <div class="mt-3">
            <label for="end_at" class="form-label">完結日期時間</label>
            <div id="end_at">
              <date-picker v-model="to_time" type="datetime" placeholder="請選擇問卷結束日期時間" input-class="form-control w-full" :disabled-date="notBeforeDate2" :disabled-time="notBeforeTime2" :time-picker-options="{start: '00:00', step:'00:30' , end: '23:30', format: 'HH:mm'}" format="YYYY-MM-DD HH:mm"></date-picker>
            </div>
            <div v-if="'to_time' in errors" class="text-danger mt-2" v-html="errors.to_time"></div>
          </div>

          <div class="mt-3" v-if="mode === 'update'">
            <div class="sm:grid grid-cols-2 gap-2">
              <div>
                <label class="form-label">只限特定用戶/組別回覆</label>
                <div class="form-switch mt-2">
                  <input v-model="form.is_private" type="checkbox" class="form-check-input">
                </div>

                <div v-if="'is_private' in errors" class="text-danger mt-2" v-html="errors.is_private"></div>
              </div>
            </div>
          </div>

          <div class="mt-3" v-if="form.is_private">
            <label class="form-label">用戶</label>
            <div class="border-2 p-3 grid grid-cols-12 gap-6">
              <div class="col-span-6 flex align-items-center" v-for="(item, key) in privateUsers" :key="key">
                <div class="form-switch mr-2">
                  <input v-model="item.has_permission" type="checkbox" :value="item.user_id" :disabled="item.is_permission_from_group" class="form-check-input" @change="onToggleUserPermission(item)">
                </div>

                <label>{{ item.display_name ?? item.contact_name ?? item.username }}</label>

                <div v-if="'is_multi_submit' in errors" class="text-danger mt-2" v-html="errors.is_multi_submit"></div>
              </div>
            </div>
          </div>

          <div class="mt-3" v-if="form.is_private">
            <label class="form-label">組別</label>
            <div class="border-2 p-3 grid grid-cols-12 gap-6">
              <div class="col-span-6 flex align-items-center" v-for="(item, key) in privateGroups" :key="key">
                <div class="form-switch mr-2">
                  <input v-model="item.has_permission" type="checkbox" :value="item.group_id" class="form-check-input" @change="onToggleGroupPermission(item)">
                </div>

                <label>{{ item.name }}</label>

                <div v-if="'is_multi_submit' in errors" class="text-danger mt-2" v-html="errors.is_multi_submit"></div>
              </div>
            </div>
          </div>

          <div class="mt-3">
            <div class="sm:grid grid-cols-2 gap-2">
              <div>
                <label class="form-label">允許多次回覆</label>
                <div class="form-switch mt-2">
                  <input v-model="form.is_multi_submit" type="checkbox" class="form-check-input">
                </div>

                <div v-if="'is_multi_submit' in errors" class="text-danger mt-2" v-html="errors.is_multi_submit"></div>

                <div class="mt-3" v-if="form.is_multi_submit">
                  <label for="description" class="form-label">可回覆次數（空置或0代表沒上限）</label>
                  <input v-model="form.quota" id="description" type="number" class="form-control w-full" placeholder="請輸入次數">
                  <div v-if="'quota' in errors" class="text-danger mt-2" v-html="errors.quota"></div>
                </div>
              </div>

              <div>
                <label class="form-label">允許修改回覆</label>
                <div class="form-switch mt-2">
                  <input v-model="form.is_response_editable" type="checkbox" class="form-check-input">
                </div>

                <div v-if="'is_response_editable' in errors" class="text-danger mt-2" v-html="errors.is_response_editable"></div>
              </div>
            </div>
          </div>

          <div class="flex justify-end align-items-center mt-5">
            <router-link v-if="!loading && !finish" to="/forms" type="button" class="btn btn-outline-secondary w-24 mr-1">取消</router-link>
            <button v-if="!loading && !finish" type="button" class="btn btn-primary w-24" @click="onSave">儲存</button>
            <span v-show="loading && !finish"><i data-loading-icon="oval" class="w-8 h-8"></i></span>
          </div>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['site', 'data'],
  data() {
    return {
      mode: 'create',
      resources: [],
      form: {
        name: null,
        description: null,
        is_published: true,
        from_time: null,
        to_time: null,
        quota: null,
        is_private: false,
        is_multi_submit: true,
        is_response_editable: true
      },

      privateUsers: [],
      privateGroups: [],

      from_time: null,
      to_time: null,

      errors: {},
      loading: false,
      finish: false
    }
  },
  watch: {
    site() {
      this.init()
    },
    data(val) {
      if (val != null) {
        this.init()
      }
    }
  },
  mounted() {
    window.tailwind.svgLoader()
    this.init()
  },
  methods: {
    async init() {
      if (this.data) {
        this.form = this.data
        this.from_time = this.form.from_time != null ? this.$moment(this.form.from_time).toDate() : null
        this.to_time = this.form.to_time != null ? this.$moment(this.form.to_time).toDate() : null

        await this.loadFormPermissions()

        this.mode = 'update'
      }
    },
    async loadFormPermissions() {
      const response = await this.getSiteFormPermissions(this.site.site_id, this.data.form_id)
      this.privateUsers = response.users
      this.privateGroups = response.groups
    },
    notBeforeDate(date) {
      if (this.to_time) {
        const now = this.to_time
        return date > new Date(now.getFullYear(), now.getMonth(), now.getDate())
      } else {
        const now = new Date()
        return date < new Date(now.getFullYear(), now.getMonth(), now.getDate())
      }
    },
    notBeforeTime(date) {
      if (this.to_time) {
        const now = this.to_time
        return date >= new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds())
      } else {
        const now = new Date()
        return date < new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds())
      }
    },
    notBeforeDate2(date) {
      const now = this.from_time ?? new Date()
      return date < new Date(now.getFullYear(), now.getMonth(), now.getDate())
    },
    notBeforeTime2(date) {
      const now = this.from_time ?? new Date()
      return date <= new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds())
    },
    async onSave() {
      this.loading = true
      this.errors = {}

      if (this.from_time != null) {
        this.form.from_time = this.$moment(this.from_time).format()
      }
      if (this.to_time != null) {
        this.form.to_time = this.$moment(this.to_time).format()
      }

      let response

      if (this.mode === 'create') {
        response = await this.createSiteForm(this.site.site_id, this.form)
      } else {
        response = await this.updateSiteForm(this.site.site_id, this.data.form_id, this.form)
      }

      if ('errors' in response.data) {
        this.errors = response.data.errors
        this.loading = false
      } else {
        let option = {
          title: (this.mode === 'create') ? '已成功新增' : '已成功更改問巻/電子表格',
          message: '請稍後，將會轉至問巻/電子表格頁面',
          type: 'success'
        }

        if (response.code === 0) {
          this.finish = true

          setTimeout(() => {
            this.$router.push('/forms')
          }, 1000)
        } else {
          this.loading = false
          option = {
            title: (this.mode === 'create') ? '未能新增問巻/電子表格' : '未能更改問巻/電子表格',
            message: response.data.message ?? '系統錯誤，請稍後再試',
            type: 'error'
          }
        }

        this.$notify(option)
      }
    },
    async onToggleUserPermission(item) {
      const response = await this.toggleSiteFormPermissions(this.site.site_id, this.data.form_id, { user_id: item.user_id })

      let option = {
        title: '成功',
        message: '已設定特定用戶',
        type: 'success'
      }

      if (response.code === 0) {
        await this.loadFormPermissions()
      } else {
        option = {
          title: '未能設定特定用戶',
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        }
      }

      this.$notify(option)
    },
    async onToggleGroupPermission(item) {
      const response = await this.toggleSiteFormPermissionsGroup(this.site.site_id, this.data.form_id, { group_id: item.group_id })

      let option = {
        title: '成功',
        message: '已設定特定組別',
        type: 'success'
      }

      if (response.code === 0) {
        await this.loadFormPermissions()
      } else {
        option = {
          title: '未能設定特定組別',
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        }
      }

      this.$notify(option)
    }
  }
}
</script>

<style scoped lang="scss">
#form-form {
  .info {
    .space {
      border: 1px solid #ccc;
      width: 90px;
      height: 32px;
    }
  }
}
</style>

<template>
  <div id="forgot-password-page" class="login">
    <div class="container sm:px-10">
      <div class="block xl:grid grid-cols-2 gap-4">
        <!-- BEGIN: Login Info -->
        <div class="hidden xl:flex flex-col min-h-screen">
        </div>
        <!-- END: Login Info -->
        <!-- BEGIN: Login Form -->
        <div class="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
          <div class="my-auto mx-auto xl:ml-20 bg-white dark:bg-darkmode-600 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto">
            <img class="mb-4" src="/images/logo.jpg">
            <h2 class="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left">
              重設密碼
            </h2>
            <div class="intro-x mt-8">
              請輸入新密碼
            </div>
            <div class="intro-x mt-2">
              <input v-model="password" type="password" class="intro-x login__input form-control py-3 px-4 block mt-2" :class="{'border-danger': error != null}" placeholder="新密碼">
              <div v-if="error != null" class="text-danger mt-2" v-html="error"></div>
            </div>
            <div class="intro-x mt-5 xl:mt-8 text-center xl:text-left">
              <button v-if="!reset" @click="onReset" class="btn btn-primary py-3 px-4 w-full xl:w-32 xl:mr-3 align-top" :disabled="reset">確認</button>
              <router-link to="/" class="btn btn-outline-secondary py-3 px-4 w-full xl:w-32 mt-3 xl:mt-0 align-top">返回</router-link>
            </div>
          </div>
        </div>
        <!-- END: Login Form -->
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      username: null,
      token: null,
      password: null,

      sent: false,
      reset: false,

      error: null
    }
  },
  async mounted() {
    // Check if user is logged in
    const accessToken = this.$cookies.get('accessToken')
    if (accessToken != null && accessToken !== '' && accessToken !== '/') {
      this.$router.push('/home')
    }

    this.username = this.$route.query.username
    this.token = this.$route.query.token

    if (this.username == null || this.username === '' || this.token == null || this.token === '') {
      this.$router.push('/')
    }
  },
  methods: {
    async onReset() {
      this.error = null

      const response = await this.resetPassword(this.username, this.token, this.password)

      if ((response.body != null && response.body.code !== 0) || response.code !== 0) {
        this.error = response.body.message
      } else {
        this.reset = true

        const option = {
          title: '成功',
          message: '已重設密碼',
          type: 'success'
        }

        this.$notify(option)
        this.$router.push('/')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>

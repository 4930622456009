<template>
  <div id="resource-booking-page">

    <div class="flex justify-end mt-8">
      <button type="button" class="btn btn-outline-secondary w-24" @click="$router.go(-1)">返回</button>
    </div>

    <div>
      <div v-if="data != null" class="intro-y xl:w-3/5 p-5 box">
        <div class="mt-3">
          <label for="user" class="form-label">類別</label>
          <div class="w-full">{{ getObjectValue(types, 'value', data.resource_type, 'key') }}</div>
        </div>

        <div class="mt-3">
          <label for="resource" class="form-label">項目名稱</label>
          <div class="w-full">{{ data ?  data.name : '-' }}</div>
        </div>

        <div class="mt-3">
          <label for="description" class="form-label">簡介</label>
          <div class="w-full">{{ data.description ?? '-' }}</div>
        </div>

        <div class="mt-3">
          <label for="address" class="form-label">地址</label>
          <div class="w-full">{{ data.address ?? '-' }}</div>
        </div>

        <div class="mt-3">
          <label for="remark" class="form-label">備註</label>
          <div class="w-full">{{ data.remark ?? '-' }}</div>
        </div>

        <div class="mt-3">
          <label for="is_available" class="form-label">可借用</label>
          <div class="w-full">{{ data.is_available ? '是' : '否' }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['auth', 'currentSite', 'permissions'],
  data() {
    return {
      mounted: false,
      data: null,

      types: [
        {
          value: 'resource_item',
          key: '物品'
        },
        {
          value: 'room',
          key: '會議室'
        }
      ]
    }
  },
  watch: {
    auth(val) {
      if (val) {
        this.init()
      }
    },
    async currentSite(val) {
      await this.init()
    }
  },
  async mounted() {
    if (!this.auth) {
      return
    }

    await this.init()
  },
  methods: {
    async init() {
      if (this.currentSite) {
        await this.loadData()

        this.mounted = true
      }
    },
    async loadData() {
      this.data = await this.getSiteResource(this.currentSite.site_id, this.$route.params.resourceId)
    }
  }
}
</script>

<style lang="scss" scoped>
#resource-booking-page {
  .leading-relaxed {
    min-height: 400px;
  }
}
</style>

import { render, staticRenderFns } from "./ResourceBookingPage.vue?vue&type=template&id=64f85e90&scoped=true&"
import script from "./ResourceBookingPage.vue?vue&type=script&lang=js&"
export * from "./ResourceBookingPage.vue?vue&type=script&lang=js&"
import style0 from "./ResourceBookingPage.vue?vue&type=style&index=0&id=64f85e90&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64f85e90",
  null
  
)

export default component.exports
<template>
  <div id="calendar-page">
    <div v-if="checkPermission(permissions, 'site.calendar.view')" class="relative">

      <div class="fixed box event-info text-white" v-if="hoverEvent" :style="`background-color: ${getObjectValue(calendars, 'calendar_id', hoverEvent.calendar_id, 'color')}; top: ${hoverRect.top + 2 + hoverRect.height}px; left: ${hoverRect.left}px;`">
        日曆：{{ getObjectValue(calendars, 'calendar_id', hoverEvent.calendar_id, 'name') }}<br>
        名稱：{{ hoverEvent.name }}<br>
        日期：{{
          $moment(hoverEvent.from_time).format('YYYY年MM月DD日 HH:mm')
        }}{{ hoverEvent.is_whole_day || hoverEvent.to_time == null ? '' : (' - ' + $moment(hoverEvent.to_time).format('YYYY年MM月DD日 HH:mm')) }}<br>
        地點：{{ hoverEvent.location ?? '-' }}
      </div>

      <div class="grid grid-cols-12 gap-6 mt-8">
        <div class="col-span-12 lg:col-span-4 2xl:col-span-3">
          <h2 class="intro-y text-lg font-medium mr-auto mt-2">
            行事曆
          </h2>

          <!-- BEGIN: File Manager Menu -->
          <div class="intro-y box p-2 mt-6">
            <div class="px-3 pb-2 mt-2 font-medium">
              分站
            </div>

            <div class="border-t border-slate-200 dark:border-darkmode-400">

              <div v-for="(item, key) in calendarSites" :key="key"
                   class="flex justify-between items-center px-3 py-2 mt-2 rounded-md">
                <div class="form-switch flex items-center">
                  <label class="pointer flex">
                    <input type="checkbox" class="form-check-input mr-2" v-model="siteIds" :value="item.site_id">
                    <div>{{ item.name }}</div>
                  </label>
                </div>

              </div>
            </div>
          </div>
          <!-- END: File Manager Menu -->

          <!-- BEGIN: File Manager Menu -->
          <div class="intro-y box p-2 mt-6">
            <div class="px-3 pb-2 mt-2 font-medium">
              日曆
            </div>

            <div class="border-t border-slate-200 dark:border-darkmode-400">

              <div v-for="(item, key) in calendars" :key="key"
                   class="flex justify-between items-center px-3 py-2 mt-2 rounded-md">
                <div class="flex-1 form-switch flex items-center">
                  <label class="w-full pointer flex align-items-center">
                    <input type="checkbox" class="form-check-input mr-2" v-model="item.show" @change="loadCalendarEvents">
                    <span class="calendar-name flex-1 p-2 text-white" :style="`background-color: ${item.color}`">{{ item.name }}</span>
                  </label>
                </div>

                <div v-if="checkPermission(permissions, 'site.calendar.edit')">
                  <router-link :to="`/update-calendar/${item.calendar_id}`"
                               class="flex items-center px-3 py-2 rounded-md">
                    <font-awesome-icon icon="fa-solid fa-pencil"/>
                  </router-link>
                </div>
              </div>

              <div class="flex justify-between items-center px-3 py-2 mt-2 rounded-md">
                <div class="flex-1 form-switch flex items-center">
                  <label class="w-full pointer flex align-items-center">
                    <input type="checkbox" class="form-check-input mr-2" v-model="showResourceBooking" @change="loadCalendarEvents">
                    <span class="calendar-name flex-1 p-2 text-white" style="background-color: rgb(245, 151, 20)">會議室及資源預借</span>
                  </label>
                </div>

                <div class="update-button-placeholder">
                </div>
              </div>

              <router-link to="/create-calendar" v-if="checkPermission(permissions, 'site.calendar.create')" class="flex items-center px-3 py-2 mt-2 rounded-md">
                <font-awesome-icon icon="fa-solid fa-plus" class="mr-2"/>
                新增日曆
              </router-link>
            </div>
          </div>
          <!-- END: File Manager Menu -->
        </div>

        <div class="box p-2 col-span-12 lg:col-span-8 2xl:col-span-9">
          <div class="flex justify-end" v-if="checkPermission(permissions, 'site.calendar.create')">
            <button class="btn btn-primary shadow-md" @click="openImportModal">匯入日曆</button>
          </div>

          <div>
            <FullCalendar class="mt-4" :options="calendarOptions"/>
          </div>
        </div>

      </div>

      <!-- BEGIN: Event Modal -->
      <div id="event-modal" class="modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h2 class="font-medium text-base mr-auto">
                {{ mode === 'create' ? '新增活動' : '更改活動' }}
              </h2>
            </div>
            <div class="modal-body p-0">
              <div class="p-5">
                <div class="text-slate-500">
                  <div class="mt-2">
                    <label class="form-label">日曆</label>
                    <select id="type" v-model="calendar_id" class="form-select" aria-label="請選擇日曆" :disabled="(mode === 'create' && !checkPermission(permissions, 'site.calendar.create')) || (mode === 'update' && !checkPermission(permissions, 'site.calendar.edit'))">
                      <option value="">--- 請選擇 ---</option>
                      <option v-for="(item, key) in calendars" :key="key" :value="item.calendar_id">{{
                          item.name
                        }}
                      </option>
                    </select>
                  </div>

                  <div class="mt-2">
                    <label class="form-label">名稱</label>
                    <input type="text" v-model="form.name" placeholder="請輸入名稱" class="form-control w-full" :disabled="(mode === 'create' && !checkPermission(permissions, 'site.calendar.create')) || (mode === 'update' && !checkPermission(permissions, 'site.calendar.edit'))"/>
                    <div v-if="'name' in errors" class="text-danger mt-2" v-html="errors.name"></div>
                  </div>

                  <div class="mt-2">
                    <label class="form-label">日期</label>
                    <div>{{
                        $moment(from_time).format('YYYY年MM月DD日')
                      }}{{ form.is_whole_day || to_time == null ? '' : (' - ' + $moment(to_time).format('YYYY年MM月DD日')) }}
                    </div>
                  </div>

                  <div class="mt-2">
                    <label class="form-label">全日</label>
                    <div class="form-switch mt-2">
                      <input v-model="form.is_whole_day" type="checkbox" class="form-check-input" :disabled="(mode === 'create' && !checkPermission(permissions, 'site.calendar.create')) || (mode === 'update' && !checkPermission(permissions, 'site.calendar.edit'))">
                    </div>

                    <div v-if="'is_whole_day' in errors" class="text-danger mt-2" v-html="errors.is_whole_day"></div>
                  </div>

                  <div class="mt-2" v-if="!form.is_whole_day">
                    <label for="display_at" class="form-label"><span class="text-danger">*</span> 開始日期時間</label>
                    <div id="display_at">
                      <date-picker v-model="from_time" type="datetime" placeholder="請選擇借用日期時間"
                                   input-class="form-control w-full" :disabled-date="notBeforeDate"
                                   :disabled-time="notBeforeTime"
                                   :disabled="(mode === 'create' && !checkPermission(permissions, 'site.calendar.create')) || (mode === 'update' && !checkPermission(permissions, 'site.calendar.edit'))"
                                   :time-picker-options="{start: '00:00', step:'00:30' , end: '23:00', format: 'HH:mm'}"
                                   format="YYYY-MM-DD HH:mm"></date-picker>
                    </div>
                    <div v-if="'from_time' in errors" class="text-danger mt-2" v-html="errors.from_time"></div>
                  </div>

                  <div class="mt-2" v-if="!form.is_whole_day">
                    <label for="end_at" class="form-label"><span class="text-danger">*</span> 結束日期時間</label>
                    <div id="end_at">
                      <date-picker v-model="to_time" type="datetime" placeholder="請選擇歸還日期時間"
                                   input-class="form-control w-full" :disabled-date="notBeforeDate2"
                                   :disabled-time="notBeforeTime2"
                                   :disabled="(mode === 'create' && !checkPermission(permissions, 'site.calendar.create')) || (mode === 'update' && !checkPermission(permissions, 'site.calendar.edit'))"
                                   :time-picker-options="{start: '00:00', step:'00:30' , end: '23:30', format: 'HH:mm'}"
                                   format="YYYY-MM-DD HH:mm"></date-picker>
                    </div>
                    <div v-if="'to_time' in errors" class="text-danger mt-2" v-html="errors.to_time"></div>
                  </div>

                  <div class="mt-2">
                    <label class="form-label">地點</label>
                    <input type="text" v-model="form.location" placeholder="請輸入地點" class="form-control w-full" :disabled="(mode === 'create' && !checkPermission(permissions, 'site.calendar.create')) || (mode === 'update' && !checkPermission(permissions, 'site.calendar.edit'))"/>
                    <div v-if="'location' in errors" class="text-danger mt-2" v-html="errors.location"></div>
                  </div>

                  <div class="mt-2">
                    <label class="form-label">備註</label>
                    <textarea v-model="form.description" placeholder="請輸入備註" class="form-control w-full" :disabled="(mode === 'create' && !checkPermission(permissions, 'site.calendar.create')) || (mode === 'update' && !checkPermission(permissions, 'site.calendar.edit'))" rows="10"></textarea>
                    <div v-if="'description' in errors" class="text-danger mt-2" v-html="errors.description"></div>
                  </div>

                  <div class="mt-2" v-if="false">
                    <label class="form-label">類別</label>
                    <input type="text" v-model="form.type" placeholder="請輸入類別" class="form-control w-full" :disabled="(mode === 'create' && !checkPermission(permissions, 'site.calendar.create')) || (mode === 'update' && !checkPermission(permissions, 'site.calendar.edit'))"/>
                    <div v-if="'type' in errors" class="text-danger mt-2" v-html="errors.type"></div>
                  </div>
                </div>
              </div>

              <div class="px-5 pb-8 text-center">
                <button type="button" class="btn btn-danger w-24 mr-1" @click="onDelete" v-if="mode === 'update' && checkPermission(permissions, 'site.calendar.delete')">刪除
                </button>
                <button type="button" data-tw-dismiss="modal" class="btn btn-outline-secondary w-24 mr-1">返回
                </button>
                <button type="button" class="btn btn-primary w-24 text-white" @click="onSubmit" v-if="(mode === 'create' && checkPermission(permissions, 'site.calendar.create')) || (mode === 'update' && checkPermission(permissions, 'site.calendar.edit'))">確定
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END: Event Modal -->

      <!-- BEGIN: Import Modal -->
      <div id="import-modal" class="modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h2 class="font-medium text-base mr-auto">
                匯入日曆
              </h2>
            </div>
            <div class="modal-body p-0">
              <div class="p-5">
                <div class="text-slate-500">
                  <div class="">
                    <label class="form-label">日曆</label>
                    <select id="type" v-model="importForm.calendar_id" class="form-select" aria-label="請選擇日曆">
                      <option value="">--- 請選擇 ---</option>
                      <option v-for="(item, key) in calendars" :key="key" :value="item.calendar_id">{{
                          item.name
                        }}
                      </option>
                    </select>
                  </div>

                  <div class="mt-4">
                    <div class="mb-2">日曆檔案(.ics檔)</div>

                    <div class="">
                      <input id="calendar-file" @change="onSelectFile($event)" type="file" hidden accept=".ics">
                      <button v-if="importForm.file == null" type="button" class="btn btn-outline-secondary" @click="openFileSelect">選擇檔案</button>
                      <button v-else type="button" class="btn btn-outline-danger" @click="onRemoveFile">取消選擇</button>
                    </div>

                    <div class="mt-2" v-if="importForm.file != null">{{ importForm.file.name }}</div>

                    <div v-if="'file' in errors" class="text-danger mt-2" v-html="errors.file"></div>
                  </div>
                </div>
              </div>

              <div class="px-5 pb-8 text-center">
                <button type="button" data-tw-dismiss="modal" class="btn btn-outline-secondary w-24 mr-1">取消
                </button>
                <button v-if="!loading" type="button" class="btn btn-primary w-24 text-white" @click="onImport" :disabled="importForm.calendar_id === '' || importForm.file == null">確定
                </button>
                <loading-ring v-if="loading"></loading-ring>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END: Import Modal -->
    </div>

    <div v-else-if="mounted">
      <div class="alert alert-danger show mt-2" role="alert">沒有權限查看</div>
    </div>
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import interactionPlugin from '@fullcalendar/interaction'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'

export default {
  props: ['auth', 'userSites', 'currentSite', 'permissions'],
  components: {
    FullCalendar
  },
  data() {
    return {
      mounted: false,
      loading: false,

      currentMonth: null,
      currentEventId: null,

      hoverEvent: null,
      hoverRect: null,

      calendarOptions: {
        height: 'auto',
        plugins: [interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin],
        eventDisplay: 'block',
        initialView: 'dayGridMonth',
        selectable: true,
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,listWeek'
        },
        buttonText: {
          today: '今天',
          month: '月',
          week: '周',
          day: '日',
          list: '表'
        },
        locale: 'zh-HK',
        events: [],
        initialDate: this.$moment(this.$route.query.date).isValid() ? this.$route.query.date : this.$moment().format('YYYY-MM-DD'),
        dateClick: (info) => {
          this.clearForm()

          if (!this.checkPermission(this.permissions, 'site.calendar.create')) {
            return
          }

          // Check if date is before today
          const selectDate = this.$moment(info.dateStr)
          const now = this.$moment()
          if (selectDate < now && !selectDate.isSame(now, 'day')) {
            const option = {
              title: '錯誤',
              message: '所選日期已過，未能新增活動',
              type: 'error'
            }

            this.$notify(option)
            return
          }

          if (info.dateStr.match(/^\d{4}-\d{2}-\d{2}$/)) {
            this.date = info.dateStr
            this.from_time = this.$moment(info.dateStr).startOf('day').toDate()
            this.to_time = this.$moment(info.dateStr).endOf('day').toDate()

            this.form.is_whole_day = true
          } else {
            const date = new Date(info.dateStr)
            this.from_time = this.$moment(date).startOf('day').toDate()

            this.form.is_whole_day = false
          }

          const el = document.querySelector('#event-modal')
          const modal = window.tailwind.Modal.getOrCreateInstance(el)
          modal.show()

          this.mode = 'create'
        },
        eventClick: (info) => {
          if (info.event.extendedProps.type === 'resource') {
            return
          }

          if (this.$router.currentRoute.fullPath.includes(`&event_id=${this.currentEventId}`)) {
            this.currentEventId = null
            this.$router.replace({ path: '/calendar', query: { date: this.currentMonth } })
          }

          this.clearForm()

          const el = document.querySelector('#event-modal')
          const modal = window.tailwind.Modal.getOrCreateInstance(el)
          modal.show()

          this.mode = 'update'
          const props = info.event.extendedProps

          this.calendar_id = props.calendar_id
          this.date = this.$moment(props.from_time).format('YYYY-MM-DD')

          this.form.is_whole_day = props.is_whole_day

          if (this.form.is_whole_day) {
            this.from_time = this.$moment(props.from_time).startOf('day').toDate()
            this.to_time = this.$moment(props.to_time).endOf('day').toDate()
          } else {
            this.from_time = this.$moment(props.from_time).toDate()
            this.to_time = this.$moment(props.to_time).toDate()
          }

          this.form.event_id = props.event_id
          this.form.name = props.name
          this.form.description = props.description
          this.form.location = props.location
          this.form.type = props.type
        },
        eventContent: (arg) => {
          const event = arg.event
          let customHtml = ''
          customHtml += `<span class='r10 font-xxs font-bold' style='overflow: hidden;' id='event-${event.extendedProps.event_id}'>${arg.timeText} ${event.title}</span>`
          return { html: customHtml }
        },
        eventMouseEnter: (event, jsEvent, view) => {
          this.hoverRect = event.el.getBoundingClientRect()
          this.hoverEvent = event.event._def.extendedProps
        },
        eventMouseLeave: (event, jsEvent, view) => {
          this.hoverEvent = null
          this.hoverRect = null
        },
        datesSet: (event) => {
          const midDate = new Date((event.start.getTime() + event.end.getTime()) / 2)
          this.currentMonth = this.$moment(midDate).format('YYYY-MM')
          if (!this.$router.currentRoute.fullPath.startsWith(`/calendar?date=${this.currentMonth}`)) {
            this.$router.replace({ path: '/calendar', query: { date: this.currentMonth } })
          }
          this.loadCalendarEvents()
        }
      },

      siteIds: [],
      calendarSites: [],
      calendars: [],

      resources: [],
      resourceBookings: [],

      showResourceBooking: true,

      mode: 'create',
      form: {
        event_id: null,
        name: null,
        description: null,
        location: null,
        from_time: null,
        to_time: null,
        type: null,
        is_whole_day: false
      },
      importForm: {
        calendar_id: '',
        file: null
      },

      calendar_id: '',
      date: null,
      from_time: null,
      to_time: null,

      errors: {}
    }
  },
  watch: {
    auth(val) {
      if (val) {
        this.init()
      }
    },
    currentSite() {
      this.init()
    },
    userSites() {
      this.init()
    },
    'form.is_whole_day'(val) {
      if (val) {
        this.from_time = this.$moment(this.date).startOf('day').toDate()
        this.to_time = this.$moment(this.date).endOf('day').toDate()
      }
    },
    async siteIds(val) {
      await this.loadResources()
      await this.loadResourceBookings()

      await this.loadCalendars()
      await this.loadCalendarEvents()
    }
  },
  async mounted() {
    if (!this.auth) {
      return
    }

    await this.init()
  },
  methods: {
    async init() {
      if (this.currentSite) {
        await this.checkCalendarPermission()
        this.siteIds = [this.currentSite.site_id]
        this.currentMonth = this.$route.query.date
        this.currentEventId = this.$route.query.event_id

        await this.loadResources()
        await this.loadResourceBookings()

        await this.loadCalendars()

        // Open event if url passed
        if (this.currentEventId) {
          this.openEvent()
        }
      }
    },
    async checkCalendarPermission() {
      for (let i = 0; i < this.userSites.length; i++) {
        if (!this.userSites[i].is_calendar_enabled) {
          continue
        }

        const response = await this.getUserSitePermission(this.userSites[i].site_id)
        if (response != null) {
          const sitePermissions = response.permissions
          if (this.checkPermission(sitePermissions, 'site.calendar.view')) {
            this.calendarSites.push(this.userSites[i])
          }
        }
      }
    },
    async loadCalendars() {
      let allCalendars = []
      for (let i = 0; i < this.siteIds.length; i++) {
        const calendars = await this.getSiteCalendars(this.siteIds[i])

        for (let j = 0; j < calendars.length; j++) {
          calendars[j].show = true
        }

        allCalendars = allCalendars.concat(calendars)
      }

      this.calendars = allCalendars
    },
    async loadCalendarEvents() {
      let events = []
      for (let j = 0; j < this.siteIds.length; j++) {
        for (let i = 0; i < this.calendars.length; i++) {
          if (this.calendars[i].show && this.calendars[i].site_id === this.siteIds[j]) {
            const data = await this.getSiteCalendarEvents(this.siteIds[j], this.calendars[i].calendar_id, 'month', this.currentMonth)
            for (let k = 0; k < data.length; k++) {
              data[k].color = this.calendars[i].color
            }
            events = events.concat(data)
          }
        }
      }

      for (let i = 0; i < events.length; i++) {
        events[i].title = events[i].name
        events[i].start = events[i].from_time
        events[i].end = events[i].to_time
        // events[i].type = 'event'
      }

      if (this.showResourceBooking) {
        for (let i = 0; i < this.resourceBookings.length; i++) {
          const item = {
            title: this.getObjectValue(this.resources, 'resource_id', this.resourceBookings[i].resource_id, 'name'),
            start: this.resourceBookings[i].from_time,
            end: this.resourceBookings[i].to_time,
            bookingId: this.resourceBookings[i].booking_id,
            type: 'resource',
            color: 'orange'
          }

          events.push(item)
        }
      }

      this.calendarOptions.events = events
    },
    async loadResources() {
      let resources = []

      for (let i = 0; i < this.siteIds.length; i++) {
        const response = await this.getSiteResources(this.siteIds[i], '', '', 99999999, 1)
        resources = resources.concat(response.data)
      }

      this.resources = resources
    },
    async loadResourceBookings(resetCurrentPage = true) {
      let resourceBookings = []

      for (let i = 0; i < this.siteIds.length; i++) {
        const response = await this.getSiteUserResourceBookings(this.siteIds[i], 99999999, 1)
        resourceBookings = resourceBookings.concat(response.data)
      }

      this.resourceBookings = resourceBookings
    },
    openEvent() {
      const element = document.getElementById(`event-${this.currentEventId}`)
      if (!element) {
        setTimeout(() => {
          this.openEvent()
        }, 1000)

        return
      }

      element.parentNode.parentNode.click()
    },
    notBeforeDate(date) {
      if (this.to_time) {
        const now = this.to_time
        return date > new Date(now.getFullYear(), now.getMonth(), now.getDate())
      } else {
        const now = new Date()
        return date < new Date(now.getFullYear(), now.getMonth(), now.getDate())
      }
    },
    notBeforeTime(date) {
      if (this.to_time) {
        const now = this.to_time
        return date >= new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds())
      } else {
        const now = new Date()
        return date < new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds())
      }
    },
    notBeforeDate2(date) {
      const now = this.from_time ?? new Date()
      return date < new Date(now.getFullYear(), now.getMonth(), now.getDate())
    },
    notBeforeTime2(date) {
      const now = this.from_time ?? new Date()
      return date <= new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds())
    },
    async onSubmit() {
      if (this.from_time != null) {
        this.form.from_time = this.$moment(this.from_time).format()
      }
      if (this.to_time != null) {
        this.form.to_time = this.$moment(this.to_time).format()
      }

      let response

      if (this.mode === 'create') {
        response = await this.createSiteCalendarEvent(this.currentSite.site_id, this.calendar_id, this.form)
      } else {
        response = await this.updateSiteCalendarEvent(this.currentSite.site_id, this.calendar_id, this.form.event_id, this.form)
      }

      if ('errors' in response.data) {
        this.errors = response.data.errors
        this.loading = false
      } else {
        let option = {
          title: (this.mode === 'create') ? '已成功新增活動' : '已成功更改活動',
          // message: '請稍後，將會轉至會議室及資源預借頁面',
          type: 'success'
        }

        if (response.code === 0) {
          this.finish = true
          this.loadCalendarEvents()

          const el = document.querySelector('#event-modal')
          const modal = window.tailwind.Modal.getOrCreateInstance(el)
          modal.hide()
        } else {
          this.loading = false
          option = {
            title: (this.mode === 'create') ? '未能新增活動' : '未能更改活動',
            message: response.data.message ?? '系統錯誤，請稍後再試',
            type: 'error'
          }
        }

        this.$notify(option)
      }
    },
    async onDelete() {
      const isDelete = confirm('你是否要刪除此活動？\n這動作不能還原。')

      if (!isDelete) {
        return
      }

      this.loading = true

      const response = await this.deleteSiteCalendarEvent(this.currentSite.site_id, this.calendar_id, this.form.event_id)

      let option = {
        title: '成功',
        message: '活動已被刪除',
        type: 'success'
      }

      if (response.code === 0) {
        this.finish = true
        this.loadCalendarEvents()

        const el = document.querySelector('#event-modal')
        const modal = window.tailwind.Modal.getOrCreateInstance(el)
        modal.hide()
      } else {
        this.loading = false
        option = {
          title: '未能刪除活動',
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        }
      }

      this.$notify(option)
    },
    clearForm() {
      this.form = {
        event_id: null,
        name: null,
        description: null,
        location: null,
        from_time: null,
        to_time: null,
        type: null,
        is_whole_day: false
      }

      this.errors = {}

      this.calendar_id = ''
      this.date = null
      this.from_time = null
      this.to_time = null
    },
    openImportModal() {
      this.onRemoveFile()

      const el = document.querySelector('#import-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.show()
    },
    onSelectFile(event) {
      this.$set(this.importForm, 'file', event.target.files[0])
      this.$forceUpdate()
    },
    openFileSelect() {
      document.getElementById('calendar-file').click()
    },
    onRemoveFile() {
      document.getElementById('calendar-file').value = null
      this.importForm.calendar_id = ''
      this.importForm.file = null
      this.$forceUpdate()
    },
    async onImport() {
      this.loading = true

      const formData = new FormData()
      formData.set('file', this.importForm.file)

      const response = await this.importSiteCalendarEvent(this.currentSite.site_id, this.importForm.calendar_id, formData)
      this.loading = false

      if (response.body && 'errors' in response.body) {
        this.errors = response.body.errors
      } else {
        let option = {
          title: '成功',
          message: '已匯入日曆',
          type: 'success'
        }

        if (response.code === 0) {
          const el = document.querySelector('#import-modal')
          const modal = window.tailwind.Modal.getOrCreateInstance(el)
          modal.hide()

          await this.loadCalendarEvents()
        } else {
          this.loading = false
          option = {
            title: '未能匯入日曆',
            message: response.data.message ?? '系統錯誤，請稍後再試',
            type: 'error'
          }
        }

        this.$notify(option)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#calendar-page {
  .calendar-name {
    border-radius: 4px;
  }

  .update-button-placeholder {
    width: calc(1.5rem + 14px);
  }

  .event-info {
    padding: 12px;
    z-index: 50;
  }
}
</style>

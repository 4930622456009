<template>
  <div id="workflow-page">

    <div class="flex justify-end mt-8">
      <router-link :to="`/workflows?type=${type}`" type="button" class="btn btn-outline-secondary w-24">返回</router-link>
    </div>

    <div v-if="checkPermission(permissions, 'system.workflow.account-workflow') && data != null" class="flex mt-2">
      <div class="news xl:w-3/5 p-5 box">

        <div class="mt-3 flex">
          <div class="flex-1">
            <label for="staff_number" class="form-label">職員編號</label>
            <div class="w-full">{{ data.staff_number ?? '-' }}</div>
          </div>
          <div class="flex-1">
            <label for="cost_centre_code" class="form-label">申請類別</label>
            <div class="w-full">{{ getObjectValue(applicationTypes, 'value', data.application_type, 'key') }}</div>
          </div>
        </div>

        <div class="col-span-11 alert alert-primary alert-dismissible show flex items-center mb-6 mt-6" role="alert">
          <span class="font-medium">基本資料</span>
        </div>

        <div class="mt-3" v-if="data.avatar">
          <label for="site_id" class="form-label">大頭圖像</label>
          <a :href="serverUrl + '/public-resources' + data.avatar" target="_blank" class="block w-48 h-48 flex-none image-fit rounded-md overflow-hidden">
            <img alt="大頭圖像" :src="serverUrl + '/public-resources' + data.avatar">
          </a>
        </div>

        <div class="mt-5">
          <div class="sm:grid grid-cols-2 gap-2">
            <div>
              <label for="centre_id" class="form-label">所屬部/組/服務單位</label>
              <div class="w-full">{{ getObjectValue(centers, 'centre_id', data.centre_id, 'abbrev') ?? '-' }}</div>
            </div>

            <div>
              <label for="position_id" class="form-label">職位</label>
              <div class="w-full">{{ getObjectValue(positions, 'position_id', data.position_id, 'name') ?? '-' }}</div>
            </div>
          </div>
        </div>

        <div class="mt-3">
          <div class="flex">
            <div class="flex-1 mr-1">
              <label class="form-label">主任級或以上</label>
              <div class="w-full">{{ data.is_high_level ? '是' : '否' }}</div>
            </div>
            <div class="flex-1 ml-1">
              <label class="form-label">註冊社工</label>
              <div class="w-full">{{ data.is_register_social_worker ? '是' : '否' }}</div>
            </div>
          </div>
        </div>

        <div class="mt-3">
          <label class="form-label">稱謂</label>
          <div class="w-full">{{ getObjectValue(titles, 'value', data.honorific_title, 'key') }}</div>
        </div>

        <div class="mt-3">
          <label class="form-label">中文名稱</label>
          <div class="w-full">{{ data.last_name_zh + data.first_name_zh }}</div>
        </div>

        <div class="mt-3">
          <label class="form-label">英文名稱</label>
          <div class="w-full">{{ checkTextEmpty(data.last_name_en) + ' ' + checkTextEmpty(data.first_name_en) + ' ' + checkTextEmpty(data.other_name_en) }}</div>
        </div>

        <div class="mt-3">
          <label class="form-label">顯示名稱</label>
          <div class="w-full">{{ data.display_name ?? '-' }}</div>
        </div>

        <div class="mt-3">
          <label class="form-label">電郵地址</label>
          <div class="w-full">{{ data.email ?? '-' }}</div>
        </div>

        <div class="mt-3 flex">
          <div class="flex-1">
            <label for="staff_number" class="form-label">辦公室電話</label>
            <div class="w-full">{{ data.office_number ?? '-' }}</div>
          </div>
          <div class="flex-1">
            <label for="cost_centre_code" class="form-label">傳真</label>
            <div class="w-full">{{ data.fax ?? '-' }}</div>
          </div>
        </div>

        <div class="mt-3 flex">
          <div class="flex-1">
            <label for="staff_number" class="form-label">手提電話</label>
            <div class="w-full">{{ data.contact_number ?? '-' }}</div>
          </div>
          <div class="flex-1">
            <label for="cost_centre_code" class="form-label">其他電話</label>
            <div class="w-full">{{ data.other_number ?? '-' }}</div>
          </div>
        </div>

        <div class="mt-3">
          <label class="form-label">出生日期</label>
          <div class="w-full">{{ data.birthdate ?? '-' }}</div>
        </div>

        <div class="mt-3">
          <label class="form-label">專長</label>
          <div class="w-full">{{ data.special_skill ?? '-' }}</div>
        </div>

        <div class="mt-3">
          <label class="form-label">關於我</label>
          <div class="w-full">{{ data.about_me ?? '-' }}</div>
        </div>

        <div class="col-span-11 alert alert-primary alert-dismissible show flex items-center mb-6 mt-6" role="alert">
          <span class="font-medium">其他資料</span>
        </div>

        <div class="mt-3">
          <label class="form-label">個人網站</label>
          <a v-if="data.personal_website" :href="data.personal_website" target="_blank" class="w-full block text-primary">{{ data.personal_website }}</a>
          <div v-else class="w-full">-</div>
        </div>

        <div class="mt-3">
          <label class="form-label">博客網站</label>
          <a v-if="data.blog_website" :href="data.blog_website" target="_blank" class="w-full block text-primary">{{ data.blog_website }}</a>
          <div v-else class="w-full">-</div>
        </div>

        <div class="mt-3">
          <label class="form-label">其他網站</label>
          <a v-if="data.other_website" :href="data.other_website" target="_blank" class="w-full block text-primary">{{ data.other_website }}</a>
          <div v-else class="w-full">-</div>
        </div>

        <div class="mt-3">
          <label class="form-label">團體及組織</label>
          <div class="w-full">{{ data.group ?? '-' }}</div>
        </div>

        <div class="mt-3">
          <label class="form-label">榮譽及獎項</label>
          <div class="w-full">{{ data.honors ?? '-' }}</div>
        </div>

        <div class="mt-3">
          <label class="form-label">榮譽及獎項</label>
          <div class="w-full">{{ data.honors ?? '-' }}</div>
        </div>

        <div class="mt-3">
          <label class="form-label">興趣</label>
          <div class="w-full">{{ data.interest ?? '-' }}</div>
        </div>

        <div class="col-span-11 alert alert-primary alert-dismissible show flex items-center mb-6 mt-6" role="alert">
          <span class="font-medium">教育</span>
        </div>

        <div class="mt-3">
          <label class="form-label">就讀學校</label>
          <div class="w-full">{{ data.school ?? '-' }}</div>
        </div>

        <div class="mt-3 flex">
          <div class="flex-1">
            <label for="academic" class="form-label">獲取的學歷</label>
            <div class="w-full">{{ data.academic ?? '-' }}</div>
          </div>

          <div class="flex-1">
            <label for="academic_department" class="form-label">學系</label>
            <div class="w-full">{{ data.academic_department ?? '-' }}</div>
          </div>

          <div class="flex-1">
            <label for="academic_other" class="form-label">其他資料</label>
            <div class="w-full">{{ data.academic_other ?? '-' }}</div>
          </div>
        </div>

        <div class="col-span-11 alert alert-primary alert-dismissible show flex items-center mb-6 mt-6" role="alert">
          <span class="font-medium">專業資格</span>
        </div>

        <div class="mt-3 flex">
          <div class="flex-1">
            <label for="professional_qualifications" class="form-label">持有的專業資格</label>
            <div class="w-full">{{ data.professional_qualifications ?? '-' }}</div>
          </div>

          <div class="flex-1">
            <label for="qualification_authority" class="form-label">頒發機構全名</label>
            <div class="w-full">{{ data.qualification_authority ?? '-' }}</div>
          </div>

          <div class="flex-1">
            <label for="qualification_level" class="form-label">已達到的程度</label>
            <div class="w-full">{{ data.qualification_level ?? '-' }}</div>
          </div>
        </div>

        <div class="col-span-11 alert alert-primary alert-dismissible show flex items-center mb-6 mt-6" role="alert">
          <span class="font-medium">工作經驗</span>
        </div>

        <div class="mt-3 flex">
          <div class="flex-1">
            <label for="past_company" class="form-label">公司名稱</label>
            <div class="w-full">{{ data.past_company ?? '-' }}</div>
          </div>

          <div class="flex-1">
            <label for="past_position" class="form-label">職位</label>
            <div class="w-full">{{ data.past_position ?? '-' }}</div>
          </div>

          <div class="flex-1">
            <label for="past_intro" class="form-label">描述</label>
            <div class="w-full">{{ data.past_intro ?? '-' }}</div>
          </div>
        </div>

        <div v-if="data.application_status === 'DRAFT' || (data.application_status === 'PENDING' && isApprover) || (data.application_status === 'PENDING_BOSS' && isHighLevelApprover)" class="flex justify-center align-items-center mt-5 pt-4 border-t border-slate-200\/60">
          <div v-if="data.application_status === 'DRAFT'" class="font-bold font-medium text-xl">確認遞交</div>
          <div v-else-if="data.application_status === 'PENDING'" class="font-bold font-medium text-xl">進行審批</div>
          <div v-else-if="data.application_status === 'PENDING_BOSS'" class="font-bold font-medium text-xl">進行最終審批</div>
        </div>

        <div class="flex justify-center align-items-center mt-3">
          <button v-if="!loading && !finish && data.application_status === 'PENDING' && isApprover" type="button" class="btn btn-primary w-24 mr-1" @click="onOpenModal('approve')">批准</button>
          <button v-if="!loading && !finish && data.application_status === 'PENDING' && isApprover" type="button" class="btn btn-danger w-24 mr-1" @click="onOpenModal('reject')">拒絕</button>
          <button v-if="!loading && !finish && data.application_status === 'PENDING' && isApprover" type="button" class="btn btn-pending w-24 mr-1" @click="onOpenModal('request-modify')">要求修改</button>

          <button v-if="!loading && !finish && data.application_status === 'PENDING_BOSS' && isHighLevelApprover" type="button" class="btn btn-primary w-24 mr-1" @click="onOpenModal('high-level-approve')">批准</button>
          <button v-if="!loading && !finish && data.application_status === 'PENDING_BOSS' && isHighLevelApprover" type="button" class="btn btn-danger w-24 mr-1" @click="onOpenModal('high-level-reject')">拒絕</button>
          <button v-if="!loading && !finish && data.application_status === 'PENDING_BOSS' && isHighLevelApprover" type="button" class="btn btn-pending w-24 mr-1" @click="onOpenModal('high-level-request-modify')">要求修改</button>

          <button v-if="!loading && !finish && data.application_status === 'DRAFT'" type="button" class="btn btn-primary w-24" @click="onConfirm">提交</button>
          <span v-show="loading && !finish"><i data-loading-icon="oval" class="w-8 h-8"></i></span>
        </div>

      </div>
      <div class="news flex-1 ml-5">
        <h2 class="text-lg font-medium">
          審批流程
        </h2>

        <!-- <div v-for="(item, key) in data.approvals" :key="key" class="text-xs sm:text-sm flex-col sm:flex-row items-center mt-2 mb-5 box p-5">
          <div class="flex items-center mb-4" v-if="item.user">
            <div class="w-12 h-12 flex-none image-fit">
              <img alt="" class="rounded-full" :src="item.user.avatar != null && item.user.avatar !== '' ? item.avatar : '/images/user.png'">
            </div>
            <div class="ml-3 mr-auto">
              <div class="font-medium">{{ item.user.first_name + " " + item.user.last_name }}</div>
              <div class="text-slate-500">{{ item.user.username }}</div>
            </div>
            <div></div>
          </div>

          <div class="">
            <div class="flex">
              <div class="mr-4 font-bold">
                審批人員
              </div>
              <div class="flex-1">
                {{ item.approver.full_name }}
              </div>
            </div>
            <div class="flex">
              <div class="mr-4 font-bold">
                審批時間
              </div>
              <div class="flex-1">
                {{ item.created_at }}
              </div>
            </div>
            <div class="flex">
              <div class="mr-4 font-bold">
                審批動作
              </div>
              <div class="flex-1">
                {{ item.is_accepted ? '批准' : '' }}
                {{ item.is_rejected ? '拒絕' : '' }}
                {{ item.request_modification ? '要求修改' : '' }}
              </div>
            </div>
            <div class="flex">
              <div class="mr-4 font-bold">
                審批內容
              </div>
              <div class="flex-1">
                {{ item.approval_message ?? '-' }}
              </div>
            </div>
          </div>
        </div> -->

        <div class="text-xs sm:text-sm flex-col sm:flex-row items-center mt-2 mb-5 box p-5">
          <div class="flex" v-for="(item, key) in data.histories" :key="key">
            <div class="mr-4 font-bold">
              {{ item.created_at }}
            </div>
            <div class="flex-1" style="word-break: break-all">
              {{ item.description }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="mounted">
      <div class="alert alert-danger show mt-2" role="alert">沒有權限查看</div>
    </div>

    <!-- BEGIN: Signature Modal -->
    <div id="signature-modal" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <!-- BEGIN: Modal Header -->
          <div class="modal-header">
            <h2 class="font-medium text-base mr-auto">
              {{ action != null ? (action.includes('high-level') ? '第三部份 -（由資訊科技填寫）' : '第二部份 - 直轄主管簽署（最低為組/服務單位主管/學校校長或副校長）') : '' }}
            </h2>
          </div>
          <!-- END: Modal Header -->
          <!-- BEGIN: Modal Body -->
          <div class="modal-body">
            <div>
              <label for="role-id" class="form-label">訊息</label>
              <textarea v-model="form.approval_message" ref="textarea" class="chat__box__input form-control dark:bg-darkmode-600 resize-none px-5 py-3 shadow-none focus:ring-0" rows="5" placeholder="請輸入訊息"></textarea>
            </div>
            <div class="mt-3" v-if="false">
              <label for="role-id" class="form-label">簽署</label>
              <div class="sign-area">
                <vue-signature ref="signature" :sigOption="option"></vue-signature>
              </div>
              <div class="flex mt-1">
                <button type="button" @click="clear" class="btn btn-outline-secondary flex-1 mr-1">清除</button>
                <button type="button" @click="undo" class="btn btn-outline-secondary flex-1">復原</button>
              </div>
            </div>
          </div>
          <!-- END: Modal Body -->
          <!-- BEGIN: Modal Footer -->
          <div class="modal-footer">
            <button type="button" data-tw-dismiss="modal" class="btn btn-outline-secondary w-24 mr-1">取消</button>
            <button v-if="!loading && !finish && action === 'request-modify'" type="button" class="btn btn-pending w-24" @click="onRequestModify">要求修改</button>
            <button v-if="!loading && !finish && action === 'reject'" type="button" class="btn btn-danger w-24" @click="onReject">拒絕</button>
            <button v-if="!loading && !finish && action === 'approve'" type="button" class="btn btn-primary w-24" @click="onApprove">批准</button>
            <button v-if="!loading && !finish && action === 'high-level-request-modify'" type="button" class="btn btn-pending w-24" @click="onHighLevelRequestModify">要求修改</button>
            <button v-if="!loading && !finish && action === 'high-level-reject'" type="button" class="btn btn-danger w-24" @click="onHighLevelReject">拒絕</button>
            <button v-if="!loading && !finish && action === 'high-level-approve'" type="button" class="btn btn-primary w-24" @click="onHighLevelApprove">批准</button>
            <span v-show="loading && !finish"><i data-loading-icon="oval" class="w-8 h-8"></i></span>
          </div>
          <!-- END: Modal Footer -->
        </div>
      </div>
    </div>
    <!-- END: Signature Modal -->
  </div>
</template>

<script>
export default {
  props: ['auth', 'permissions', 'allSites', 'currentSite'],
  data() {
    return {
      type: 'account',
      mounted: false,
      data: null,

      configs: [],
      isApprover: false,
      isHighLevelApprover: false,

      action: null,

      form: {
        approval_message: null,
        signature_image: null,
        signature_image_base64: null
      },

      loading: false,
      finish: false,

      applicationTypes: [
        {
          key: '新增',
          value: 'ADD'
        },
        {
          key: '更新',
          value: 'UPDATE'
        },
        {
          key: '刪除',
          value: 'DELETE'
        }
      ],
      titles: [
        {
          key: '先生',
          value: 'Mr'
        },
        {
          key: '太太',
          value: 'Mrs'
        },
        {
          key: '小姐',
          value: 'Miss'
        },
        {
          key: '女士',
          value: 'Ms'
        },
        {
          key: '夫人',
          value: 'Madam'
        },
        {
          key: '博士',
          value: 'Dr'
        }
      ],

      option: {
        penColor: 'rgb(0, 0, 0)',
        backgroundColor: 'rgba(255,255,255)'
      },

      allStatus: [
        { name: '全部', value: 'ALL' },
        { name: '已提交', value: 'SUBMITTED' },
        { name: '草稿', value: 'DRAFT' },
        { name: '成功', value: 'SUCCESS' },
        { name: '等待最終審批', value: 'PENDING_BOSS' },
        { name: '要求修改', value: 'REQUEST_MODIFICATION' },
        { name: '已拒絕', value: 'REJECTED' },
        { name: '等待審批', value: 'PENDING' }
      ],

      positions: [],
      centers: []
    }
  },
  computed: {
    serverUrl() {
      return '' + process.env.VUE_APP_SERVER_URL
    }
  },
  watch: {
    auth(val) {
      if (val) {
        this.init()
      }
    },
    async currentSite(val) {
      await this.init()
    }
  },
  async mounted() {
    if (!this.auth) {
      return
    }

    await this.init()
  },
  methods: {
    async init() {
      if (this.allSites && this.currentSite && this.permissions) {
        await this.loadConfigs()
        await this.loadCenters()
        await this.loadPositions()
        await this.loadData()

        this.mounted = true
      }
    },
    async loadConfigs() {
      this.configs = await this.getSystemConfig('workflow')

      this.isApprover = (this.checkPermission(this.permissions, 'system.workflow.account-workflow.approval')) || (parseInt(this.configs.approver_site_id) === parseInt(this.currentSite.site_id))
      this.isHighLevelApprover = (this.checkPermission(this.permissions, 'system.workflow.account-workflow.approval')) || (parseInt(this.configs.high_level_approver_site_id) === parseInt(this.currentSite.site_id))
    },
    async loadCenters() {
      this.centers = await this.getCenters()
    },
    async loadPositions() {
      this.positions = await this.getPositions()
    },
    async loadData() {
      this.data = await this.getWorkflow(this.type, this.$route.params.applicationId)
      if (this.data.code === 0) {
        this.loadUser()
      } else if (this.data.code && this.data.code !== 0) {
        this.data = null
      }
    },
    async loadUser() {
      for (let i = 0; i < this.data.approvals.length; i++) {
        const user = await this.getUser(this.data.approvals[i].user_id)
        this.$set(this.data.approvals[i], 'user', user)
      }
    },
    onOpenModal(action) {
      this.action = action
      const el = document.querySelector('#signature-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.show()
    },
    async onConfirm() {
      this.loading = true

      const response = await this.confirmWorkflow(this.type, this.data.application_id)

      let option = {
        title: '成功',
        message: '已提交申請表',
        type: 'success'
      }

      if (response.code === 0) {
        this.finish = true

        setTimeout(() => {
          this.$router.push(`/workflows?type=${this.type}`)
        }, 1000)
      } else {
        option = {
          title: '未能提交申請表',
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        }
      }

      this.$notify(option)
      this.loading = false
    },
    async onRequestModify() {
      this.loading = true

      const response = await this.requestModifyWorkflow(this.type, this.data.application_id, this.form)

      let option = {
        title: '成功',
        message: '已要求修改申請表',
        type: 'success'
      }

      if (response.code === 0) {
        this.finish = true
        this.hideModal()

        setTimeout(() => {
          this.$router.push(`/workflows?type=${this.type}`)
        }, 1000)
      } else {
        option = {
          title: '未能要求修改申請表',
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        }
      }

      this.$notify(option)
      this.loading = false
    },
    async onReject() {
      this.loading = true

      const response = await this.rejectWorkflow(this.type, this.data.application_id, this.form)

      let option = {
        title: '成功',
        message: '已拒絕申請',
        type: 'success'
      }

      if (response.code === 0) {
        this.finish = true
        this.hideModal()

        setTimeout(() => {
          this.$router.push(`/workflows?type=${this.type}`)
        }, 1000)
      } else {
        option = {
          title: '未能拒絕申請',
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        }
      }

      this.$notify(option)
      this.loading = false
    },
    async onApprove() {
      this.loading = true

      const response = await this.approveWorkflow(this.type, this.data.application_id, this.form)

      let option = {
        title: '成功',
        message: '已批準申請',
        type: 'success'
      }

      if (response.code === 0) {
        this.finish = true
        this.hideModal()

        setTimeout(() => {
          this.$router.push(`/workflows?type=${this.type}`)
        }, 1000)
      } else {
        option = {
          title: '未能批準申請',
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        }
      }

      this.$notify(option)
      this.loading = false
    },
    async onHighLevelRequestModify() {
      this.loading = true

      const response = await this.highLevelRequestModifyWorkflow(this.type, this.data.application_id, this.form)

      let option = {
        title: '成功',
        message: '已要求修改申請表',
        type: 'success'
      }

      if (response.code === 0) {
        this.finish = true
        this.hideModal()

        setTimeout(() => {
          this.$router.push(`/workflows?type=${this.type}`)
        }, 1000)
      } else {
        option = {
          title: '未能要求修改申請表',
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        }
      }

      this.$notify(option)
      this.loading = false
    },
    async onHighLevelReject() {
      this.loading = true

      const response = await this.highLevelRejectWorkflow(this.type, this.data.application_id, this.form)

      let option = {
        title: '成功',
        message: '已拒絕申請',
        type: 'success'
      }

      if (response.code === 0) {
        this.finish = true
        this.hideModal()

        setTimeout(() => {
          this.$router.push(`/workflows?type=${this.type}`)
        }, 1000)
      } else {
        option = {
          title: '未能拒絕申請',
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        }
      }

      this.$notify(option)
      this.loading = false
    },
    async onHighLevelApprove() {
      this.loading = true

      const response = await this.highLevelApproveWorkflow(this.type, this.data.application_id, this.form)

      let option = {
        title: '成功',
        message: '已批準申請',
        type: 'success'
      }

      if (response.code === 0) {
        this.finish = true
        this.hideModal()

        setTimeout(() => {
          this.$router.push(`/workflows?type=${this.type}`)
        }, 1000)
      } else {
        option = {
          title: '未能批準申請',
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        }
      }

      this.$notify(option)
      this.loading = false
    },
    checkIsEmpty() {
      const isEmpty = this.$refs.signature.isEmpty()

      if (isEmpty) {
        const option = {
          title: '未能提交',
          message: '請先簽署',
          type: 'error'
        }

        this.$notify(option)
      } else {
        this.form.signature_image_base64 = this.$refs.signature.save()
      }

      return isEmpty
    },
    hideModal() {
      const el = document.querySelector('#signature-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.hide()
    },
    clear() {
      this.$refs.signature.clear()
    },
    undo() {
      this.$refs.signature.undo()
    }
  }
}
</script>

<style lang="scss" scoped>
#workflow-page {
}
</style>

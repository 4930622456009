<template>
  <div id="login-page" class="login" v-if="!loggedIn">
    <div class="container sm:px-10">
      <div class="block xl:grid grid-cols-2 gap-4">
        <!-- BEGIN: Login Info -->
        <div class="hidden xl:flex flex-col min-h-screen">
        </div>
        <!-- END: Login Info -->
        <!-- BEGIN: Login Form -->
        <div class="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
          <div class="my-auto mx-auto xl:ml-20 bg-white dark:bg-darkmode-600 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto">
            <img class="mb-4" src="/images/logo.jpg">
            <h2 v-if="false" class="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left">
              TWGHs 內聯網
            </h2>
            <div v-if="false" class="intro-x mt-2 text-slate-400 xl:hidden text-center">A few more clicks to sign in to your account. Manage all your e-commerce accounts in one place</div>
            <div class="intro-x mt-8">
              <input v-model="username" type="text" class="intro-x login__input form-control py-3 px-4 block" :class="{'border-danger': 'username' in errors}" placeholder="使用者名稱">
              <div v-if="'username' in errors" class="text-danger mt-2" v-html="errors.username"></div>

              <input v-model="password" type="password" class="intro-x login__input form-control py-3 px-4 block mt-4" :class="{'border-danger': 'password' in errors}" placeholder="密碼">
              <div v-if="'password' in errors" class="text-danger mt-2" v-html="errors.password"></div>
            </div>
            <div class="intro-x flex justify-end text-slate-600 dark:text-slate-500 text-xs sm:text-sm mt-4">
              <div v-if="false" class="flex items-center mr-auto">
                <input id="remember-me" type="checkbox" class="form-check-input border mr-2">
                <label class="cursor-pointer select-none" for="remember-me">Remember me</label>
              </div>
              <router-link to="/forgot-password">忘記密碼？</router-link>
            </div>
            <div class="intro-x mt-5 xl:mt-8 text-center xl:text-left">
              <button @click="onLogin" class="btn btn-primary py-3 px-4 w-full xl:w-32 xl:mr-3 align-top">登入</button>
              <button v-if="false" class="btn btn-outline-secondary py-3 px-4 w-full xl:w-32 mt-3 xl:mt-0 align-top">Register</button>
            </div>

            <div v-if="'account' in errors" class="alert alert-danger show flex items-center mt-2" role="alert">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" icon-name="alert-octagon" data-lucide="alert-octagon" class="lucide lucide-alert-octagon w-6 h-6 mr-2"><polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon><line x1="12" y1="8" x2="12" y2="12"></line><line x1="12" y1="16" x2="12.01" y2="16"></line></svg>
              <span v-html="errors.account"></span>
            </div>

            <div v-if="false" class="intro-x mt-10 xl:mt-24 text-slate-600 dark:text-slate-500 text-center xl:text-left"> By signin up, you agree to our <a class="text-primary dark:text-slate-200" href="">Terms and Conditions</a> &amp; <a class="text-primary dark:text-slate-200" href="">Privacy Policy</a> </div>
          </div>
        </div>
        <!-- END: Login Form -->
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      loggedIn: false,

      username: null,
      password: null,

      errors: {}
    }
  },
  async mounted() {
    // Check if user is logged in
    const accessToken = this.$cookies.get('accessToken')
    if (accessToken != null && accessToken !== '' && accessToken !== '/') {
      this.$router.push('/home')
    }
  },
  methods: {
    async onLogin() {
      this.errors = {}

      const response = await this.login(this.username, this.password)

      if (response.body && 'errors' in response.body) {
        this.errors = response.body.errors

        if ('account' in this.errors) {
          this.username = null
          this.password = null
        }
      } else if (response.body && 'message' in response.body && response.code !== 0) {
        this.errors = {
          account: response.body.message
        }

        this.username = null
        this.password = null
      } else {
        this.loggedIn = true

        const date = this.$moment(response.data.expiredAt).toDate()
        await this.$cookies.set('accessToken', response.data.accessToken, date.toUTCString())
        // Check enterUrl value
        const enterUrl = await this.$cookies.get('enterUrl')

        // Get Profile
        await this.$root.$children[0].getProfileAndCurrentSite()
        await this.$root.$children[0].getUserSites()

        if (enterUrl != null && enterUrl !== '' && enterUrl !== '/') {
          this.$cookies.remove('enterUrl')
          this.$router.push(enterUrl)
        } else {
          this.$router.push('/home')
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>

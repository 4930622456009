<template>
  <div id="workflow-page">

    <div class="flex justify-end mt-8">
      <router-link :to="`/workflows?type=${type}`" type="button" class="btn btn-outline-secondary w-24">返回</router-link>
    </div>

    <div v-if="checkPermission(permissions, 'system.workflow.email-workflow') && data != null" class="flex mt-2">
      <div class="news xl:w-3/5 p-5 box">
        <div class="mt-3">
          <label for="name" class="form-label">申請狀態</label>
          <div id="name" class="w-full">{{ getObjectValue(allStatus, 'value', data.application_status, 'name') }}</div>
        </div>

        <div class="mt-3">
          <label for="name" class="form-label">英文名稱</label>
          <div id="name" class="w-full">{{ getObjectValue(titles, 'value', data.honorific_title, 'key') + ' ' + data.last_name + ' ' + data.middle_name + '' + data.first_name }}</div>
        </div>

        <div class="mt-3">
          <label for="site_id" class="form-label">服務單位</label>
          <div class="w-full">{{ getObjectValue(centers, 'centre_id', data.centre_id, 'abbrev') }} ({{ getObjectValue(centers, 'centre_id', data.centre_id, 'name_en') }})</div>
        </div>

        <div class="mt-3 flex">
          <div class="flex-1">
            <label for="staff_number" class="form-label">職員編號</label>
            <div class="w-full">{{ data.staff_number ?? '-' }}</div>
          </div>
          <div class="flex-1">
            <label for="cost_centre_code" class="form-label">單位編號</label>
            <div class="w-full">{{ data.cost_centre_code ?? '-' }}</div>
          </div>
        </div>

        <div class="mt-3">
          <label for="contact_number" class="form-label">辦公室電話</label>
          <div class="w-full">{{ data.contact_number ?? '-' }}</div>
        </div>

        <div class="mt-3">
          <label for="account_category" class="form-label">帳戶類別</label>
          <div class="w-full">{{ getObjectValue(accountCategories, 'value', data.account_category, 'key') }}</div>
        </div>

        <div class="mt-3">
          <label for="request_for" class="form-label">申請要求</label>
          <div class="w-full">{{ getObjectValue(requestTypes, 'value', data.request_for, 'key') }}</div>
        </div>

        <div class="mt-3 flex" v-if="data.request_for === 1 || data.request_for === 2">
          <div class="flex-1">
            <label for="request_email_1" class="form-label">首選帳戶電郵</label>
            <div class="w-full">{{ data.request_email_1 ?? '-' }}</div>
          </div>
          <div class="flex-1">
            <label for="request_email_2" class="form-label">次選帳戶電郵</label>
            <div class="w-full">{{ data.request_email_2 ?? '-' }}</div>
          </div>
        </div>

        <div class="mt-3" v-if="data.request_for === 3 || data.request_for === 4">
          <label for="request_email_1" class="form-label">現有帳戶電郵</label>
          <div class="w-full">{{ data.request_email_1 ?? '-' }}</div>
        </div>

        <div class="mt-3" v-if="data.request_for === 5">
          <label for="request_message" class="form-label">請詳述</label>
          <div class="w-full">{{ data.request_message ?? '-' }}</div>
        </div>

        <div class="mt-3">
          <label for="reason_type" class="form-label">申請原因</label>
          <div class="w-full">{{ getObjectValue(reasonTypes, 'value', data.reason_type, 'key') }}</div>
        </div>

        <div class="mt-3">
          <label for="reason" class="form-label">如申請功能/職務帳戶，必須詳述原因</label>
          <div class="w-full">{{ data.reason ?? '-' }}</div>
        </div>

        <div class="mt-3">
          <label for="note" class="form-label">備註欄</label>
          <div class="w-full">{{ data.note ?? '-' }}</div>
        </div>

        <div v-if="data.application_status === 'DRAFT' || (data.application_status === 'PENDING' && isApprover) || (data.application_status === 'PENDING_BOSS' && isHighLevelApprover)" class="flex justify-center align-items-center mt-5 pt-4 border-t border-slate-200\/60">
          <div v-if="data.application_status === 'DRAFT'" class="font-bold font-medium text-xl">確認遞交</div>
          <div v-else-if="data.application_status === 'PENDING'" class="font-bold font-medium text-xl">進行審批</div>
          <div v-else-if="data.application_status === 'PENDING_BOSS'" class="font-bold font-medium text-xl">進行最終審批</div>
        </div>

        <div class="flex justify-center align-items-center mt-3">
          <button v-if="!loading && !finish && data.application_status === 'PENDING' && isApprover" type="button" class="btn btn-pending w-24 mr-1" @click="onOpenModal('request-modify')">要求修改</button>
          <button v-if="!loading && !finish && data.application_status === 'PENDING' && isApprover" type="button" class="btn btn-danger w-24 mr-1" @click="onOpenModal('reject')">拒絕</button>
          <button v-if="!loading && !finish && data.application_status === 'PENDING' && isApprover" type="button" class="btn btn-primary w-24 mr-1" @click="onOpenModal('approve')">批准</button>
          <button v-if="!loading && !finish && data.application_status === 'PENDING_BOSS' && isHighLevelApprover" type="button" class="btn btn-pending w-24 mr-1" @click="onOpenModal('high-level-request-modify')">要求修改</button>
          <button v-if="!loading && !finish && data.application_status === 'PENDING_BOSS' && isHighLevelApprover" type="button" class="btn btn-danger w-24 mr-1" @click="onOpenModal('high-level-reject')">拒絕</button>
          <button v-if="!loading && !finish && data.application_status === 'PENDING_BOSS' && isHighLevelApprover" type="button" class="btn btn-primary w-24 mr-1" @click="onOpenModal('high-level-approve')">批准</button>
          <button v-if="!loading && !finish && data.application_status === 'DRAFT'" type="button" class="btn btn-primary w-24" @click="onConfirm">提交</button>
          <span v-show="loading && !finish"><i data-loading-icon="oval" class="w-8 h-8"></i></span>
        </div>

      </div>
      <div class="news flex-1 ml-5">
        <h2 class="text-lg font-medium">
          審批流程
        </h2>

        <div v-for="(item, key) in data.approvals" :key="key" class="text-xs sm:text-sm flex-col sm:flex-row items-center mt-2 mb-5 box p-5">
          <div class="flex items-center" v-if="item.user">
            <div class="w-12 h-12 flex-none image-fit">
              <img alt="" class="rounded-full" :src="item.user.avatar_url != null && item.user.avatar_url !== '' ? item.avatar_url : '/images/user.png'">
            </div>
            <div class="ml-3 mr-auto">
              <div class="font-medium">{{ item.user.first_name + " " + item.user.last_name }}</div>
              <div class="text-slate-500">{{ item.user.username }}</div>
            </div>
            <div></div>
          </div>

          <div class="mt-4">
            <div class="flex">
              <div class="mr-4 font-bold">
                審批人員
              </div>
              <div class="flex-1">
                {{ item.approver.full_name }}
              </div>
            </div>
            <div class="flex">
              <div class="mr-4 font-bold">
                審批時間
              </div>
              <div class="flex-1">
                {{ item.created_at }}
              </div>
            </div>
            <div class="flex">
              <div class="mr-4 font-bold">
                審批內容
              </div>
              <div class="flex-1">
                {{ item.approval_message ?? '-' }}
              </div>
            </div>
            <div class="flex">
              <div class="mr-4 font-bold">
                審批動作
              </div>
              <div class="flex-1">
                {{ item.is_accepted ? '批准' : '' }}
                {{ item.is_rejected ? '拒絕' : '' }}
                {{ item.request_modification ? '要求修改' : '' }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="mounted">
      <div class="alert alert-danger show mt-2" role="alert">沒有權限查看</div>
    </div>

    <!-- BEGIN: Signature Modal -->
    <div id="signature-modal" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <!-- BEGIN: Modal Header -->
          <div class="modal-header">
            <h2 class="font-medium text-base mr-auto">
              {{ action != null ? (action.includes('high-level') ? '第三部份 -（由資訊科技填寫）' : '第二部份 - 直轄主管簽署（最低為組/服務單位主管/學校校長或副校長）') : '' }}
            </h2>
          </div>
          <!-- END: Modal Header -->
          <!-- BEGIN: Modal Body -->
          <div class="modal-body">
            <div>
              <label for="role-id" class="form-label">訊息</label>
              <textarea v-model="form.approval_message" ref="textarea" class="chat__box__input form-control dark:bg-darkmode-600 resize-none px-5 py-3 shadow-none focus:ring-0" rows="5" placeholder="請輸入訊息"></textarea>
            </div>
            <div class="mt-3" v-if="action === 'approve' && isApprover">
              <label for="role-id" class="form-label">簽署</label>
              <select id="category_id" v-model="signatureKey" class="form-select" aria-label="請選擇簽名">
                <option value="">--- 請選擇 ---</option>
                <option v-for="(item, key) in signatures" :key="key" :value="key">{{ item.full_name }}</option>
              </select>
              <div class="sign-area mt-1" v-if="signatureKey !== ''">
                <img :src="signatures[signatureKey].signature_base64" class="w-full" />
                <vue-signature v-if="false" ref="signature" :sigOption="option"></vue-signature>
              </div>
              <div class="flex mt-1" v-if="false">
                <button type="button" @click="clear" class="btn btn-outline-secondary flex-1 mr-1">清除</button>
                <button type="button" @click="undo" class="btn btn-outline-secondary flex-1">復原</button>
              </div>
            </div>
          </div>
          <!-- END: Modal Body -->
          <!-- BEGIN: Modal Footer -->
          <div class="modal-footer">
            <button type="button" data-tw-dismiss="modal" class="btn btn-outline-secondary w-24 mr-1">取消</button>
            <button v-if="!loading && !finish && action === 'request-modify'" type="button" class="btn btn-pending w-24" @click="onRequestModify">要求修改</button>
            <button v-if="!loading && !finish && action === 'reject'" type="button" class="btn btn-danger w-24" @click="onReject">拒絕</button>
            <button v-if="!loading && !finish && action === 'approve'" type="button" class="btn btn-primary w-24" @click="onApprove">批准</button>
            <button v-if="!loading && !finish && action === 'high-level-request-modify'" type="button" class="btn btn-pending w-24" @click="onHighLevelRequestModify">要求修改</button>
            <button v-if="!loading && !finish && action === 'high-level-reject'" type="button" class="btn btn-danger w-24" @click="onHighLevelReject">拒絕</button>
            <button v-if="!loading && !finish && action === 'high-level-approve'" type="button" class="btn btn-primary w-24" @click="onHighLevelApprove">批准</button>
            <span v-show="loading && !finish"><i data-loading-icon="oval" class="w-8 h-8"></i></span>
          </div>
          <!-- END: Modal Footer -->
        </div>
      </div>
    </div>
    <!-- END: Signature Modal -->
  </div>
</template>

<script>
export default {
  props: ['auth', 'currentSite', 'permissions'],
  data() {
    return {
      type: 'email',
      mounted: false,
      data: null,

      configs: [],
      isApprover: false,
      isHighLevelApprover: false,

      action: null,

      centers: [],

      form: {
        approval_message: null,
        signature_image: null,
        signature_image_base64: null
      },

      loading: false,
      finish: false,

      accountCategories: [
        {
          key: '行政總監/科主任',
          value: 1
        },
        {
          key: '服務單位主任',
          value: 2
        },
        {
          key: '學校校長',
          value: 3
        },
        {
          key: '部門主管',
          value: 4
        },
        {
          key: '行政人員',
          value: 5
        },
        {
          key: '總部行政秘書',
          value: 6
        },
        {
          key: '一般使用者帳戶',
          value: 7
        },
        {
          key: '其他 (功能職務帳戶)',
          value: 8
        }
      ],
      requestTypes: [
        {
          key: '新增使用者帳戶',
          value: 1
        },
        {
          key: '新增功能職務帳戶',
          value: 2
        },
        {
          key: '更改帳戶資料',
          value: 3
        },
        {
          key: '刪除帳戶',
          value: 4
        },
        {
          key: '其他',
          value: 5
        }
      ],
      reasonTypes: [
        {
          key: '入職',
          value: 1
        },
        {
          key: '轉職',
          value: 2
        },
        {
          key: '離職',
          value: 3
        }
      ],

      option: {
        penColor: 'rgb(0, 0, 0)',
        backgroundColor: 'rgba(255,255,255)'
      },

      signatures: [],
      signatureKey: '',

      allStatus: [
        { name: '全部', value: 'ALL' },
        { name: '已提交', value: 'SUBMITTED' },
        { name: '草稿', value: 'DRAFT' },
        { name: '成功', value: 'SUCCESS' },
        { name: '等待最終審批', value: 'PENDING_BOSS' },
        { name: '要求修改', value: 'REQUEST_MODIFICATION' },
        { name: '已拒絕', value: 'REJECTED' },
        { name: '等待審批', value: 'PENDING' }
      ]
    }
  },
  watch: {
    auth(val) {
      if (val) {
        this.init()
      }
    },
    async currentSite(val) {
      await this.init()
    }
  },
  async mounted() {
    if (!this.auth) {
      return
    }

    await this.init()
  },
  methods: {
    async init() {
      if (this.currentSite) {
        await this.loadConfigs()
        await this.loadCenters()
        await this.loadData()

        this.mounted = true
      }
    },
    async loadCenters() {
      this.centers = await this.getCenters()
    },
    async loadConfigs() {
      this.configs = await this.getSystemConfig('workflow')

      this.isApprover = parseInt(this.configs.approver_site_id) === parseInt(this.currentSite.site_id)
      this.isHighLevelApprover = parseInt(this.configs.high_level_approver_site_id) === parseInt(this.currentSite.site_id)

      if (this.isApprover) {
        this.loadSignatures()
      }
    },
    async loadSignatures() {
      this.signatures = await this.getWorkflowSignature()
    },
    async loadData() {
      this.data = await this.getWorkflow(this.type, this.$route.params.applicationId)
      if (this.data.code === 0) {
        this.loadUser()
      }
    },
    async loadUser() {
      for (let i = 0; i < this.data.approvals.length; i++) {
        const user = await this.getUser(this.data.approvals[i].user_id)
        this.$set(this.data.approvals[i], 'user', user)
      }
    },
    onOpenModal(action) {
      this.action = action
      const el = document.querySelector('#signature-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.show()
    },
    async onConfirm() {
      this.loading = true

      const response = await this.confirmWorkflow(this.type, this.data.application_id)

      let option = {
        title: '成功',
        message: '已提交申請表',
        type: 'success'
      }

      if (response.code === 0) {
        this.finish = true

        setTimeout(() => {
          this.$router.push(`/workflows?type=${this.type}`)
        }, 1000)
      } else {
        option = {
          title: '未能提交申請表',
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        }
      }

      this.$notify(option)
      this.loading = false
    },
    async onRequestModify() {
      this.loading = true

      const response = await this.requestModifyWorkflow(this.type, this.data.application_id, this.form)

      let option = {
        title: '成功',
        message: '已要求修改申請表',
        type: 'success'
      }

      if (response.code === 0) {
        this.finish = true
        this.hideModal()

        setTimeout(() => {
          this.$router.push(`/workflows?type=${this.type}`)
        }, 1000)
      } else {
        option = {
          title: '未能要求修改申請表',
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        }
      }

      this.$notify(option)
      this.loading = false
    },
    async onReject() {
      this.loading = true

      const response = await this.rejectWorkflow(this.type, this.data.application_id, this.form)

      let option = {
        title: '成功',
        message: '已拒絕申請',
        type: 'success'
      }

      if (response.code === 0) {
        this.finish = true
        this.hideModal()

        setTimeout(() => {
          this.$router.push(`/workflows?type=${this.type}`)
        }, 1000)
      } else {
        option = {
          title: '未能拒絕申請',
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        }
      }

      this.$notify(option)
      this.loading = false
    },
    async onApprove() {
      this.loading = true

      if (this.signatureKey !== '') {
        this.form.signature_image = this.signatures[this.signatureKey].signature_path
      }

      const response = await this.approveWorkflow(this.type, this.data.application_id, this.form)

      let option = {
        title: '成功',
        message: '已批準申請',
        type: 'success'
      }

      if (response.code === 0) {
        this.finish = true
        this.hideModal()

        setTimeout(() => {
          this.$router.push(`/workflows?type=${this.type}`)
        }, 1000)
      } else {
        option = {
          title: '未能批準申請',
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        }
      }

      this.$notify(option)
      this.loading = false
    },
    async onHighLevelRequestModify() {
      this.loading = true

      const response = await this.highLevelRequestModifyWorkflow(this.type, this.data.application_id, this.form)

      let option = {
        title: '成功',
        message: '已要求修改申請表',
        type: 'success'
      }

      if (response.code === 0) {
        this.finish = true
        this.hideModal()

        setTimeout(() => {
          this.$router.push(`/workflows?type=${this.type}`)
        }, 1000)
      } else {
        option = {
          title: '未能要求修改申請表',
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        }
      }

      this.$notify(option)
      this.loading = false
    },
    async onHighLevelReject() {
      this.loading = true

      const response = await this.highLevelRejectWorkflow(this.type, this.data.application_id, this.form)

      let option = {
        title: '成功',
        message: '已拒絕申請',
        type: 'success'
      }

      if (response.code === 0) {
        this.finish = true
        this.hideModal()

        setTimeout(() => {
          this.$router.push(`/workflows?type=${this.type}`)
        }, 1000)
      } else {
        option = {
          title: '未能拒絕申請',
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        }
      }

      this.$notify(option)
      this.loading = false
    },
    async onHighLevelApprove() {
      this.loading = true

      const response = await this.highLevelApproveWorkflow(this.type, this.data.application_id, this.form)

      let option = {
        title: '成功',
        message: '已批準申請',
        type: 'success'
      }

      if (response.code === 0) {
        this.finish = true
        this.hideModal()

        setTimeout(() => {
          this.$router.push(`/workflows?type=${this.type}`)
        }, 1000)
      } else {
        option = {
          title: '未能批準申請',
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        }
      }

      this.$notify(option)
      this.loading = false
    },
    checkIsEmpty() {
      const isEmpty = this.$refs.signature.isEmpty()

      if (isEmpty) {
        const option = {
          title: '未能提交',
          message: '請先簽署',
          type: 'error'
        }

        this.$notify(option)
      } else {
        this.form.signature_image_base64 = this.$refs.signature.save()
      }

      return isEmpty
    },
    hideModal() {
      const el = document.querySelector('#signature-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.hide()
    },
    clear() {
      this.$refs.signature.clear()
    },
    undo() {
      this.$refs.signature.undo()
    }
  }
}
</script>

<style lang="scss" scoped>
#workflow-page {
}
</style>

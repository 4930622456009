<template>
  <div id="knowledge-form">
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-6">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box p-5">

          <div class="mt-3">
            <label for="site_id" class="form-label">分站</label>
            <div id="site_id" class="w-full" v-if="site">{{ site.name }}</div>
            <div v-if="'site_id' in errors" class="text-danger mt-2" v-html="errors.site_id"></div>
          </div>

          <div class="mt-3">
            <label for="type_id" class="form-label"><span class="text-danger">*</span> 類別</label>
            <select id="type_id" v-model="form.type_id" class="form-select" aria-label="請選擇類別">
              <option value="">--- 請選擇 ---</option>
              <template v-for="(item, key) in types" >
                <option :value="item.type_id" :key="key">{{ item.name }}</option>
                <option v-for="(child, index) in item.children" :value="child.type_id" :key="`${key}-${index}`">- {{ child.name }}</option>
              </template>
            </select>
            <div v-if="'type_id' in errors" class="text-danger mt-2" v-html="errors.type_id"></div>
          </div>

          <div class="mt-3">
            <label for="description" class="form-label"><span class="text-danger">*</span> 標題</label>
            <input v-model="form.title" id="description" type="text" class="form-control w-full" placeholder="請輸入標題">
            <div v-if="'title' in errors" class="text-danger mt-2" v-html="errors.title"></div>
          </div>

          <div class="mt-3">
            <label for="editor" class="form-label"><span class="text-danger">*</span> 內容</label>
            <el-tiptap v-model="form.content" :extensions="extensions" />
            <div v-if="'content' in errors" class="text-danger mt-2" v-html="errors.content"></div>
          </div>

          <div class="mt-3">
            <label for="extra_links" class="form-label">其他連結</label>
            <input v-model="form.extra_links" id="extra_links" type="text" class="form-control w-full" placeholder="請輸入其他連結">
            <div v-if="'extra_links' in errors" class="text-danger mt-2" v-html="errors.extra_links"></div>
          </div>

          <div class="mt-3">
            <label for="display_order" class="form-label">顯示次序</label>
            <input v-model="form.display_order" id="display_order" type="number" class="form-control w-full" placeholder="請輸入顯示次序">
            <div v-if="'display_order' in errors" class="text-danger mt-2" v-html="errors.display_order"></div>
          </div>

          <div class="mt-3">
            <label for="status" class="form-label"><span class="text-danger">*</span> 狀態</label>
            <select id="status" v-model="form.status" class="form-select" aria-label="請選擇狀態">
              <option value="">--- 請選擇 ---</option>
              <option v-for="(item, key) in allStatus" :key="key" :value="item.value">{{ item.name }}</option>
            </select>
            <div v-if="'status' in errors" class="text-danger mt-2" v-html="errors.status"></div>
          </div>

          <div class="flex justify-end align-items-center mt-5">
            <router-link v-if="!loading && !finish" :to="mode === 'update' ? `/faq?type=${data.type_id}` : '/faq'" type="button" class="btn btn-outline-secondary w-24 mr-1">取消</router-link>
            <button v-if="!loading && !finish" type="button" class="btn btn-primary w-24" @click="onSave">儲存</button>
            <span v-show="loading && !finish"><i data-loading-icon="oval" class="w-8 h-8"></i></span>
          </div>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>
  </div>
</template>

<script>
import {
  // all extensions
  Doc,
  Text,
  Paragraph,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Link,
  Image,
  Blockquote,
  ListItem,
  BulletList, // use with ListItem
  OrderedList, // use with ListItem
  TodoItem,
  TodoList, // use with TodoItem
  TextAlign,
  Indent,
  HorizontalRule,
  HardBreak,
  History,
  // LineHeight,
  // Iframe,
  // CodeBlock,
  // TrailingNode,
  Table, // use with TableHeader, TableCell, TableRow
  TableHeader,
  TableCell,
  TableRow,
  // FormatClear,
  TextColor,
  TextHighlight,
  FontType,
  FontSize,
  // Preview,
  // Print,
  Fullscreen,
  CodeView
  // SelectAll,
} from 'element-tiptap'

import InsertYoutube from '../extension/insertYoutube/index'
import InsertFile from '../extension/insertFile/index'

import codemirror from 'codemirror'
import 'codemirror/lib/codemirror.css' // import base style
import 'codemirror/mode/xml/xml.js' // language
import 'codemirror/addon/selection/active-line.js' // require active-line.js
import 'codemirror/addon/edit/closetag.js' // autoCloseTags

export default {
  props: ['site', 'data', 'auth', 'currentSite', 'permissions'],
  data() {
    return {
      mode: 'create',
      types: [],
      form: {
        site_id: null,
        type_id: '',
        title: null,
        content: '',
        extra_links: null,
        display_order: 0,
        status: ''
      },
      errors: {},
      loading: false,
      finish: false,

      post_date: null,
      display_at: null,
      end_at: null,

      allStatus: [
        { name: '草稿', value: 'DRAFT' },
        { name: '公開', value: 'PUBLISHED' }
      ],

      extensions: [
        new Doc(),
        new Text(),
        new Paragraph(),
        new Heading({ level: 6 }),
        new Bold({ bubble: true }),
        new Underline({ bubble: true }),
        new Italic({ bubble: true }),
        new Strike({ bubble: true }),
        new Link({ bubble: true }),
        new Image(),
        new InsertFile(this.auth, this.currentSite, this.permissions),
        new InsertYoutube(),
        new Blockquote(),
        new TextAlign(),
        new ListItem(),
        new BulletList({ bubble: true }),
        new OrderedList({ bubble: true }),
        new TodoItem(),
        new TodoList(),
        new Indent(),
        new HardBreak(),
        new HorizontalRule({ bubble: true }),
        new Fullscreen(),
        new CodeView({
          codemirror,
          codemirrorOptions: {
            styleActiveLine: true,
            autoCloseTags: true
          }
        }),
        new History(),
        new Table(),
        new TableHeader(),
        new TableCell(),
        new TableRow(),
        new TextColor(),
        new TextHighlight(),
        new FontType({
          fontTypes: {
            Arial: 'Arial',
            'Arial Black': 'Arial Black',
            Impact: 'Impact',
            Tahoma: 'Tahoma',
            'Times New Roman': 'Times New Roman',
            monospace: 'monospace',
            新細明體: '新細明體',
            細明體: '細明體',
            標楷體: '標楷體',
            微軟正黑體: '微軟正黑體',
            微軟雅黑體: '微軟雅黑體'
          }
        }),
        new FontSize()
      ],
      fontList: ['新細明體', '細明體', '標楷體', '微軟正黑體', '微軟雅黑體', 'serif', 'sans-serif', 'monospace', 'Times New Roman', 'Arial', 'Helvetica', 'Calibri']
    }
  },
  watch: {
    data(val) {
      this.init()
    }
  },
  created() {
  },
  mounted() {
    window.tailwind.svgLoader()
    this.loadSiteKnowledgeTypes()
    this.init()
  },
  methods: {
    init() {
      if (this.data != null) {
        this.form = this.data
        this.post_date = this.form.post_date != null ? this.$moment(this.form.post_date).toDate() : null
        this.display_at = this.form.display_at != null ? this.$moment(this.form.display_at).toDate() : null
        this.end_at = this.form.end_at != null ? this.$moment(this.form.end_at).toDate() : null
        this.mode = 'update'
      }
    },
    getFontName(font) {
      return font.toLowerCase().replace(/\s/g, '-')
    },
    async loadSiteKnowledgeTypes() {
      this.types = await this.getSiteFaqTypes(this.site.site_id)
    },
    notBeforeDate(date) {
      const now = new Date()
      return date < new Date(now.getFullYear(), now.getMonth(), now.getDate())
    },
    notBeforeTime(date) {
      const now = new Date()
      return date < new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds())
    },
    async onSave() {
      this.loading = true
      this.errors = {}

      this.form.site_id = this.site.site_id
      this.form.content = this.trim(this.form.content, '<p><br></p>')

      if (this.post_date != null) {
        this.form.post_date = this.$moment(this.post_date).format()
      }
      if (this.display_at != null) {
        this.form.display_at = this.$moment(this.display_at).format()
      }
      if (this.end_at != null) {
        this.form.end_at = this.$moment(this.end_at).format()
      }

      let response

      if (this.mode === 'create') {
        response = await this.createSiteFaqBase(this.site.site_id, this.form)
      } else {
        response = await this.updateSiteFaqBase(this.site.site_id, this.data.knowledge_id, this.form)
      }

      if (response.body && 'errors' in response.body) {
        this.errors = response.body.errors
        this.loading = false
      } else {
        let option = {
          title: (this.mode === 'create') ? '已成功新增FAQ' : '已成功更新FAQ',
          message: '請稍後，將會轉至FAQ頁面',
          type: 'success'
        }

        if (response.code === 0) {
          this.finish = true

          setTimeout(() => {
            this.$router.push(`/faq?type=${this.form.type_id}`)
          }, 1000)
        } else {
          this.loading = false
          option = {
            title: (this.mode === 'create') ? '未能新增FAQ' : '未能更新FAQ',
            message: response.data.message ?? '系統錯誤，請稍後再試',
            type: 'error'
          }
        }

        this.$notify(option)
      }
    }
  }
}
</script>

<style scoped lang="scss">
#knowledge-form {
  ::v-deep {
    .ql-editor {
      height: 100%;
      flex: 1;
      overflow-y: auto;
      width: 100%;
    }
  }
}
</style>

<template>
  <div id="contact-page">

    <div class="flex justify-end mt-8">
      <router-link to="/address-books" type="button" class="btn btn-outline-secondary w-24">返回</router-link>
    </div>

    <div class="mt-2">
      <div v-if="data != null" class="intro-y xl:w-3/5 p-5 box">
        <div class="mt-3">
          <label for="site_id" class="form-label">分站</label>
          <div id="site_id" class="w-full" v-if="currentSite">{{ currentSite.name }}</div>
        </div>

        <div class="mt-3">
          <label for="last_name" class="form-label">用戶</label>
          <div class="w-full">{{ data.user != null ? ((data.user.last_name ?? '') + ' ' + (data.user.first_name ?? '')) : '-' }}</div>
        </div>

        <div class="mt-3">
          <label for="staff_number" class="form-label">職員編號</label>
          <div class="w-full">{{ data.staff_number ?? '-' }}</div>
        </div>

        <div class="mt-3" v-if="data.avatar">
          <label for="site_id" class="form-label">大頭圖像</label>
          <a :href="serverUrl + '/public-resources' + data.avatar" target="_blank" class="block w-48 h-48 flex-none image-fit rounded-md overflow-hidden">
            <img alt="大頭圖像" :src="serverUrl + '/public-resources' + data.avatar">
          </a>
        </div>

        <div class="mt-3">
          <div class="flex">
            <div class="flex-1 mr-1">
              <label for="last_name" class="form-label">所屬部/組/服務單位</label>
              <div class="w-full">{{ data.centre ? data.centre.abbrev : '-' }}</div>
            </div>
            <div class="flex-1 ml-1">
              <label for="last_name" class="form-label">職位</label>
              <div class="w-full">{{ data.position ?? '-' }}</div>
            </div>
          </div>
        </div>

        <div class="mt-3">
          <div class="flex">
            <div class="flex-1 mr-1">
              <label for="last_name" class="form-label">主任級或以上</label>
              <div class="w-full">{{ data.is_high_level ? '是' : '否' }}</div>
            </div>
            <div class="flex-1 ml-1">
              <label for="last_name" class="form-label">註冊社工</label>
              <div class="w-full">{{ data.is_register_social_worker ? '是' : '否' }}</div>
            </div>
          </div>
        </div>

        <div class="mt-3">
          <label class="form-label">稱謂</label>
          <div class="w-full">{{ getObjectValue(titles, 'value', data.honorific_title, 'key') }}</div>
        </div>

        <div class="mt-3">
          <label class="form-label">中文名稱</label>
          <div class="w-full">{{ isEmpty(data.full_name_zh) }}</div>
        </div>

        <div class="mt-3">
          <label class="form-label">英文名稱</label>
          <div class="w-full">{{ isEmpty(data.full_name) + ' ' + checkTextEmpty(data.other_name) }}</div>
        </div>

        <div class="mt-3">
          <label class="form-label">顯示名稱</label>
          <div class="w-full">{{ data.display_name ?? '-' }}</div>
        </div>

        <div class="mt-3">
          <label for="last_name" class="form-label">電郵地址</label>
          <div>
            <div class="w-full">{{ data.user.email ?? '-' }}</div>
          </div>
        </div>

        <div class="mt-3">
          <div class="flex">
            <div class="flex-1 mr-1">
              <label for="fax" class="form-label">辦公室電話</label>
              <div class="w-full">{{ data.user.office_number ?? '-' }}</div>
            </div>
            <div class="flex-1 ml-1">
              <label for="fax" class="form-label">傳真</label>
              <div class="w-full">{{ data.fax ?? '-' }}</div>
            </div>
          </div>
        </div>

        <div class="mt-3">
          <div class="flex">
            <div class="flex-1 mr-1">
              <label for="fax" class="form-label">手提電話</label>
              <div class="w-full">{{ data.user.contact_number ?? '-' }}</div>
            </div>
            <div class="flex-1 ml-1">
              <label for="fax" class="form-label">其他電話</label>
              <div class="w-full">{{ data.user.other_number ?? '-' }}</div>
            </div>
          </div>
        </div>

        <div class="mt-3">
          <label for="last_name" class="form-label">出生日期</label>
          <div class="w-full">{{ data.birthdate ? ( $moment(data.birthdate).format('YYYY年MM月DD日') ?? '-' ) : '-' }}</div>
        </div>

        <div class="mt-3">
          <label for="last_name" class="form-label">地址</label>
          <div class="w-full">{{ data.address ?? '-' }}</div>
        </div>

        <div class="mt-3">
          <label for="last_name" class="form-label">電話號碼</label>
          <div v-if="data.numbers.length > 0">
            <div v-for="(item, key) in data.numbers" :key="key" class="w-full">{{ `${item.number} (${item.label})` }}</div>
          </div>
          <div v-else>
            -
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['auth', 'currentSite', 'permissions'],
  data() {
    return {
      mounted: false,
      data: null,
      user: null,

      titles: [
        {
          key: '先生',
          value: 'Mr'
        },
        {
          key: '太太',
          value: 'Mrs'
        },
        {
          key: '小姐',
          value: 'Miss'
        },
        {
          key: '女士',
          value: 'Ms'
        },
        {
          key: '夫人',
          value: 'Madam'
        },
        {
          key: '博士',
          value: 'Dr'
        }
      ]
    }
  },
  computed: {
    serverUrl() {
      return '' + process.env.VUE_APP_SERVER_URL
    }
  },
  watch: {
    auth(val) {
      if (val) {
        this.init()
      }
    },
    async currentSite(val) {
      await this.init()
    }
  },
  async mounted() {
    if (!this.auth) {
      return
    }

    await this.init()
  },
  methods: {
    async init() {
      if (this.currentSite) {
        await this.loadData()

        this.mounted = true
      }
    },
    async loadData() {
      this.data = await this.getSiteContact(this.currentSite.site_id, this.$route.params.contactId)
    },
    isEmpty(val) {
      return (val != null && val !== '' ? val : '-')
    }
  }
}
</script>

<style lang="scss" scoped>
#contact-page {
  .leading-relaxed {
    min-height: 400px;
  }
}
</style>
